import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
export const servicesValueArr = [
  { value: "community_safety_and_cleanliness", label: "Completed Clean-up or Request Clean-up",path:"CustomForm" },
  { value: "harm_reduction_form", label: "Harm Reduction Supplies Distribution",path:"HarmReductionForm" },
  { value: "referrals_and_warm_hand_offs", label: "Referrals and Warm Hand Offs",path:"HealthcareAccessForm" },
  { value: "performed_and_facilitated_care", label: "Performed and Facilitated Care",path:"AdministratedServices"},
];
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: string;
    id: string;
    // Customizable Area End
}

// Customizable Area Start
interface SubData {
  id:number,
  date:Date,
  type:string
}
// Customizable Area End

interface S {
    // Customizable Area Start
    isToggled:boolean,
    selectedFilter: string,
    selectedService: string,
    isServicesOpen: boolean,
    data:SubData[],
    selectedDate: Date | null; 
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class SubmittedFormsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getFormsListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isToggled: false,
            selectedFilter:"All",
            selectedService:"",
            data: [],
              isServicesOpen: true,
              selectedDate: new Date(),
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFormsListApiCallId !== "" &&
            this.getFormsListApiCallId === apiRequestCallId
          ){
            if(responseJson && responseJson.data){
              this.setState({
                data: responseJson.data.map((form:any) => ({
                  id: form.id,
                  date: new Date(form.attributes.created_at),
                  type: form.type
                }))
              });      
             }
             else{
              this.showAlert("Alert", "Something went wrong.");
             }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    getToken = () => {
      const tokenMsg: Message = new Message(
        getName(MessageEnum.SessionRequestMessage)
      );
      this.send(tokenMsg);
    };
    
    async componentDidMount() {
      this.getSubmittedForms();
    }

    componentDidUpdate(prevProps: Props, prevState: S) {
      if (
        this.state.selectedFilter !== prevState.selectedFilter ||
        this.state.selectedService !== prevState.selectedService ||
        this.state.selectedDate !== prevState.selectedDate
      ) {
        this.getSubmittedForms();
      }
    }
    
    getSubmittedForms = () =>{
  
       const { selectedFilter, selectedDate, selectedService } = this.state;

       let endpointUrl = configJSON.getFormsListApiEndPoint;

       let queryParams: string[] = [];

       if (selectedFilter === 'Date' && selectedDate) {
        const formattedDate = selectedDate.toLocaleDateString('en-GB'); 
        queryParams.push(`date=${formattedDate}`);
       }

       if (selectedFilter === 'Type' && selectedService) {
        queryParams.push(`form_type=${selectedService}`);
       }

       if (queryParams.length > 0) {
        endpointUrl += `?filter_by=${selectedFilter.toLowerCase()}&` + queryParams.join('&');
      }

        const header = {
          "Content-Type": configJSON.getUserListApiContentType,
           token: localStorage.getItem("authToken"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getFormsListApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpointUrl
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getFormsListApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

    handleGoBack = () =>{
        window.history.back();
    }
    handleToggle = () => {
        this.setState((prevState) => ({
          isToggled: !prevState.isToggled,
        }));
      }
    
    handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedFilter: event.target.value });
    };
    
    handleServiceToggle = () => {
        this.setState((prevState) => ({
          isServicesOpen: !prevState.isServicesOpen,
        }));
      }

    handleServiceChange = ( value: string) => {
        this.setState({ selectedService: value });
      };
    
    handleDateChange = (date: Date | null):void => {
        this.setState({ selectedDate: date });
      };
    
    gotoPage(page: string){
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
      };

    getServiceLabel = (type:string) => {
        const service = servicesValueArr.find(service => service.value === type);
        return service && service.label
    };
    getServicePath = (type:string, id:number) => {
        const service = servicesValueArr.find(service => service.value === type);
        const path = service ? service.path: "";
        const formid = id;
        const formtype = type;
        setStorageData("formId", formid);
        setStorageData("formType", formtype);
        setStorageData("showEdit", true);
        return path;
    };
    // Customizable Area End
}
