import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Modal,
  Divider,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  RadioProps,
  Radio,
  styled,
  Select,
  Paper,
  TextField,
  Stack,
  Card,
  CardContent,
  Grid
} from "@mui/material";
import DatePicker from 'react-datepicker';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { View } from "@builder/component-library";
import { filterIcon,arrow } from "./assets";
import { ReferralItem } from "./ReservationsItemController";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from "@mui/icons-material/Close";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

function BpRadio(props: RadioProps) {
  return (
      <Radio
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          {...props}
      />
  );
}
// Customizable Area End

import ReservationsItemController, {
  Props,
  configJSON,
  HistoryItem,
} from "./ReservationsItemController";
import { FlatList } from "react-native";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ReservationsItem extends ReservationsItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
  async componentDidMount() {
  }

  renderReferral = (referral: ReferralItem) => {
    return (
      <Card sx={webStyle.cardBoxStyle}>
      <CardContent>
      <Box>
          <Typography sx={webStyle.refHeading}>
            {referral.attributes?.organization_name}
          </Typography>
          <Typography sx={webStyle.refSub}>
            {referral.attributes?.sub_service_name}
          </Typography >
          <Typography sx={webStyle.refSub}>
            {referral.attributes?.status}
          </Typography>
          <Typography sx={webStyle.refSub}>
           {this.formatDate(referral.attributes?.updated_at ? referral.attributes.updated_at: referral.attributes?.created_at)}
          </Typography>
        </Box>
      </CardContent>
        <Grid data-test-id="openModalButton" item xs={2}>
           <div style={{height:"48px",width:"48px", backgroundColor:"#FCE38B",borderRadius:"8px",textAlign:"center",verticalAlign:"middle",marginRight:6}}> 
              <TrendingFlatIcon data-test-id="refActionBtn"  onClick={()=>this.getReferralDetail(referral)} sx={{fontSize:"24px",fill:"black",verticalAlign:"middle",marginTop:"25%"}}/></div>
      
         </Grid>
    </Card>
    );
  };

renderModal = () => {
  const { modalType, cancellationReason ,isModalOpen} = this.state;

  return (
    
    <Modal
      open={isModalOpen}
      onClose={this.handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "330px",
          bgcolor: 'background.paper',
          backgroundColor:"#F6F6F3",
          // border: '2px solid #000',
         overflowY:"auto",
         maxHeight:"100vh",
          boxShadow: 24,borderRadius:"8px",
          p: 4,
        }}
      >
        {(() => {
          switch (modalType) {
            case "confirm":
              return (
                <>
                  <CloseIcon data-test-id="btnModalIcon1" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()}/>
                  <Typography id="modal-title" sx={webStyle.heading}>Confirm Referral</Typography>
                  <Box id="modal-description">
                    {this.renderSharedContent()}
                    <Box sx={webStyle.warningBox}>
                      <Typography variant="body2" color="#D97706" sx={{ fontWeight: "700" }}>
                      You are about to confirm the Referral. Are you sure?
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Button onClick={this.handleClose} variant="outlined" color="error" sx={{ width: '50%',fontWeight: "700" }}  data-test-id="cancelReferral">No</Button>
                      <Button
                        data-test-id="confirmReferral"
                        onClick={()=>this.confirmReferral()}
                        variant="contained"
                        sx={{...webStyle.commonBtn,width:"50%"}}
                      >
                        Yes
                      </Button>
                    </Stack>
                  </Box>
                </>
              );

            case "update":
              return (
                <>
                  <CloseIcon data-test-id="btnModalIcon1" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()}/>
                  <Typography id="modal-title" sx={webStyle.heading}>Edit Referral</Typography>
                  {this.state.updateRef?
                  <span> {this.renderSharedContentForbed()}</span>:
                  <Box id="modal-description">
                  {this.renderSharedContent()}
                {this.updateContent()}
                       
                  <Stack direction="row" sx={{marginY:2}} spacing={2} justifyContent="flex-end">
                    <Button onClick={this.handleClose} variant="outlined" color="error" sx={{width:"50%",fontWeight: "700"}} data-test-id="editCancelBtn">Cancel</Button>
                    <Button
                      onClick={()=>this.setState({updateRef:true})}
                      variant="contained"
                      data-test-id="editUpdateBtn"
                      sx={{...webStyle.commonBtn,width:"50%"}}
                    >
                      Update
                    </Button>
                  </Stack>
                </Box>
                 }
            
                </>
              );

            case "cancel":
              return (
                <>
                  <CloseIcon data-test-id="btnModalIcon1" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()}/>
                  <Typography id="modal-title" sx={webStyle.heading}>Cancel Referral</Typography>
                  <Box id="modal-description">
                    {this.renderSharedContent()}
                    <Typography sx={webStyle.title}>Cancellation Reason</Typography> 
                    <TextField
                      placeholder="Reason"
                      fullWidth
                      multiline
                      rows={3}
                      data-test-id="cancelReason"
                      value={cancellationReason}
                      onChange={(e) => this.setState({ cancellationReason: e.target.value })}
                      sx={{ marginBottom:"10px"}}
                    />
                    <Box sx={webStyle.warningBox}>
                      <Typography variant="body2" color="#D97706" sx={{ fontWeight: "700" }}>
                      You are about to cancel the Referral. <br/>
                      Are you sure you want to cancel the referral?
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                      <Button onClick={this.handleClose} variant="outlined" color="error" sx={{width:"50%",fontWeight: "700"}} data-test-id="CancelModalNoBtn">No</Button>
                      <Button
                        onClick={()=>this.cancelReferral()}
                        variant="contained"
                        data-test-id="cancelModalOkBtn"
                        sx={{...webStyle.commonBtn,width:"50%"}}
                      >
                        Yes
                      </Button>
                    </Stack>
                  </Box>
                </>
              );

            default:
              return (
                <>
                  <CloseIcon data-test-id="btnModalIcon1" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()}/>
                  <Typography id="modal-title" sx={webStyle.heading}>Referral Information</Typography>
                  <Box id="modal-description">
                    {this.renderSharedContent()}
                    <Stack spacing={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        data-test-id="confirmRef"
                        onClick={()=>this.handleChangeModal("confirm")}
                        sx={webStyle.commonBtn}>
                        Confirm Referral
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        data-test-id="updateRef"
                        onClick={()=>this.handleChangeModal("update")}
                        sx={webStyle.commonBtn}
                      >
                        Edit Referral
                      </Button>
                      <Button
                        fullWidth
                        variant="outlined"
                        data-test-id="cancelRef"
                        onClick={()=>this.handleChangeModal("cancel")}
                        sx={{
                          color: '#D32F2F',
                          borderColor: '#D32F2F',
                          height:"44px",fontWeight: "700",
                          textTransform:"none",
                          '&:hover': {
                            borderColor: '#D32F2F',
                          },
                        }}
                      >
                        Cancel Referral
                      </Button>
                    </Stack>
                  </Box>
                </>
              );
          }
        })()}
      </Box>
    </Modal>
  );
};
formatDate = (dateString:string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
updateContent = () =>{
  return(
    <span>
         <FormControl fullWidth variant="outlined"
      sx={webStyle.formControl}>  
        <Select
       
          // multiple
          value={this.state.selectedRefOrg.organization_name?this.state.selectedRefOrg.organization_name:""}
          // onChange={this.handleSelectChange}
          displayEmpty
          // onClose={(this.updateEstimatedTotal)}
        data-test-id="SelectItem"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          renderValue={() => this.state.selectedRefOrg.organization_name?this.state.selectedRefOrg.organization_name:'Select items'
          }
          sx={webStyle.selectMenu}
        >
          {this.state.bedOrgList?.map((item, index) => (
            <MenuItem
            key={item.id}
            data-test-id="menuItem"
           
            >
         <Typography   onClick={(text:any)=>this.handleSelectChange(item)} sx={{padding:1}} textAlign={"left"}>{item.organization_name}</Typography>
         </MenuItem>
          ))}
        </Select>
      </FormControl>
      {this.state.refOrgDetails.organization.attributes.organization_name?
     <span>
                      <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px",textAlign:"left" }} >
                      <Typography sx={[webStyle.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                          Organization
                          </Typography>
                      <Typography sx={[webStyle.selectedValue,{fontWeight:"700",fontSize:"16px",
                          font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.organization_name  }</Typography>
                      <Typography sx={[webStyle.selectedTitle,
                          {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                          Contact Person
                          </Typography>
                      <Typography sx={[webStyle.selectedValue,{fontWeight:"700",
                          fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.contact_person}</Typography>
                      <Typography sx={[webStyle.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                          Contact Phone</Typography>
                      <Typography sx={[webStyle.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.organization_phone}</Typography>
                      <Typography sx={[webStyle.selectedTitle,
                          {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                      <Typography sx={[webStyle.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                          {this.state.refOrgDetails.organization.attributes.address}</Typography>
                     </Grid> 
                     <Divider sx={{ my: 2,height:"2px" }} />
                     <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", textAlign:"left"}}>
                     <Typography sx={[webStyle.selectedTitle,
                          {fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                        Bed Information
                          </Typography>
                          <Typography sx={[webStyle.selectedTitle,
                          {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                       Bed Available
                          </Typography>
                          {this.state.refOrgDetails.is_bed_availble?
                           <Typography sx={[webStyle.selectedTitle,
                              {marginLeft:3,fontWeight:"700",fontSize:"16px",font:"Inter",color:"#059669"}]}>
                           There are beds available
                              </Typography>:
                               <Typography sx={[webStyle.selectedTitle,
                                  {marginLeft:3,fontWeight:"700",fontSize:"16px",font:"Inter",color:"#DC2626"}]}>
                               No beds available
                                  </Typography>}
                    </Box></span>:null}
    </span>
  )
}
renderSharedContent = () => {
  return (
      <Box>
         <Typography sx={webStyle.refSub}>
         {this.state.selectedReferral.attributes.sub_service_name}
      </Typography>
      <Typography sx={webStyle.refSub} gutterBottom>
       <span style={{fontWeight:700}}> {this.state.selectedReferral?.attributes?.status.charAt(0).toUpperCase() + this.state.selectedReferral?.attributes?.status.slice(1)}:</span> {this.formatDate(this.state.selectedReferral.attributes.created_at)}
      </Typography>
      <Stack spacing={2} sx={{background:"#FFFFFF",borderRadius:"8px",padding:"8%",margin:"10px 0",fontWeight: "700",}}>
      <Typography sx={webStyle.subTitle1}>Referral Information</Typography>
        <Box>
          <Typography sx={webStyle.subTitle}>Organization</Typography>
          <Typography sx={webStyle.title}>{this.state.selectedReferral.attributes.organization_info.attributes.organization_name}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Contact Person</Typography>
          <Typography sx={webStyle.title}>{this.state.selectedReferral.attributes.organization_info.attributes.contact_person}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Contact Phone</Typography>
          <Typography sx={webStyle.title}>{this.state.selectedReferral.attributes.organization_info.attributes.organization_phone}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Address</Typography>
          <Typography sx={webStyle.title}>{this.state.selectedReferral.attributes.organization_info.attributes.address}</Typography>
        </Box>
      </Stack>
      <Divider sx={{ borderBottom: "2px solid #D9D9D9",margin:"8% 0" }} />
    </Box>
  );
};
renderSharedContentForbed = () => {
  return (

      <Box>

         <Typography sx={webStyle.refSub}>
         {this.state.selectedReferral.attributes.sub_service_name}
      </Typography>
      <Typography sx={webStyle.refSub} gutterBottom>
       <span style={{fontWeight:700}}> 

        {this.state.selectedReferral?.attributes?.status.charAt(0).toUpperCase() + this.state.selectedReferral?.attributes?.status.slice(1)}:</span> {this.formatDate(this.state.selectedReferral.attributes.created_at)}
      </Typography>
      <Stack spacing={2} 
      sx={{background:"#FFFFFF",borderRadius:"8px",
      padding:"8%",margin:"10px 0",fontWeight: "700",}}>
      <Typography sx={webStyle.subTitle1}>
        Referral Information</Typography>
        <Box>
          <Typography sx={webStyle.subTitle}>Organization</Typography>
          <Typography sx={webStyle.title}>{this.state.refOrgDetails.organization.attributes.organization_name}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Contact Person</Typography>
          <Typography sx={webStyle.title}>{this.state.refOrgDetails.organization.attributes.contact_person}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Contact Phone</Typography>
          <Typography sx={webStyle.title}>{this.state.refOrgDetails.organization.attributes.organization_phone}</Typography>
        </Box>
        <Box>
          <Typography sx={webStyle.subTitle}>Address</Typography>
          <Typography sx={webStyle.title}>{this.state.refOrgDetails.organization.attributes.address}</Typography>
        </Box>
      </Stack>
      <Divider sx={{ borderBottom: "2px solid #D9D9D9",margin:"8% 0" }} />
      <Box sx={webStyle.warningBox}>
                      <Typography variant="body2" color="#D97706" sx={{ fontWeight: "700" }}>
                      You are about to update the Referral information. Are you sure?
                      </Typography>
                    </Box>  
      <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                        <Grid item xs={6}> 
                            <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none',  color: "black", 
                      font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px"
                      ,border:"2px red solid",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                    onClick={()=>this.setState({isModalOpen:false,updateRef:false})}
                      data-test-id={"editcancelButton1"}>No </Button></Grid>
                        <Grid item xs={6}>
                          <Button sx={[{paddingLeft:"28%", paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:this.state.refOrgDetails.is_bed_availble?"#F5C502":"#E2E8F0", 
                      color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}

                    disabled={!this.state.refOrgDetails.is_bed_availble}
                    onClick={()=>this.updateReferral()}
                      data-test-id={"btnEditmodalButton"}>Yes 
                      </Button>
                      
                        </Grid>
                      </Grid> 
    </Box>
  );
};

renderSuccessModal = () => {
  return(
  <Modal
    open= {this.state.successModal}
    onClose={()=>this.setState({successModal:false})}
    data-test-id={"btnModal1"}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={webStyle.modal}>
    <CloseIcon data-test-id="btnModalIcon1modal" sx={{marginLeft:"93%",height:"20px"}} onClick={()=>this.setState({successModal:false})} />
      <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px",textAlign:"start"}}>{this.state.title}</Typography>
      {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "green",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
      <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px",textAlign:"center",marginTop:3}}>{this.state.subTitle}</Typography>
    </Box>
  </Modal>
  )
}


organizations = [
    { value: "acacia_network", label: "Acacia Network"},
    { value: "samaritan_daytop_village", label: "Samaritan Daytop Village"},
    { value: "VIP_community_services", label: "VIP Community Services"},
];
tabs = ['All Referrals', 'Enrolled', 'Updated', 'Cancelled'];

   // Customizable Area End
  render() {
 // Customizable Area Start
 const {isToggled,selectedFilter,selectedOrganization,isOrgOpen,selectedDate,selectedStatus} = this.state;
 // Customizable Area End
    return (
 // Customizable Area Start
 <NavigationMenu navigation={this.props.navigation} id={""}>
 <View style={{ backgroundColor: "#f3f3f1" }}>
   <Container maxWidth="md" sx={{padding:"3% 5%"}}>
     <Box sx={webStyle.container}>
       <Box onClick={this.handleGoBack} sx={webStyle.goback}>
         <ArrowBackIosIcon sx={webStyle.arrowIcon} />
         <Typography sx={webStyle.goBackText}>Go Back</Typography>
       </Box>
       <Typography variant="h6" sx={webStyle.heading}>Referrals</Typography>
       <Typography variant="h6" sx={webStyle.subHeading}>Outreach Workers Performance</Typography>
     </Box>
     <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 2,
            margin:"7% 0%"
          }}
        >
          {this.tabs.map((tab) => (
            <Button
              key={tab}
              variant="contained"
              onClick={() => this.handleTabClick(tab)}
              data-test-id="referralstatusbtn"
              sx={{
                backgroundColor: selectedStatus === tab ? '#F5C502' : '#E2E8F0',
                color: selectedStatus === tab ? '#2D2A24' : '#64748B',
                textTransform: 'none',
                fontSize:"16px",
                fontWeight:700,
                borderRadius: '25px',
                '&:hover': {
                  backgroundColor: selectedStatus === tab ? '#E0B801' : '#CFD8E3',
                },
              }}
            >
              {tab}
            </Button>
          ))}
        </Box>
     <Box sx={webStyle.mainBox}>
                            <Box sx={webStyle.filterBox}>
                                <Typography sx={webStyle.filterText}>Filter:</Typography>
                                <div>
                                    <Button onClick={this.handleToggle} data-test-id="toggle_button" sx={webStyle.filterButton}>
                                        <img src={filterIcon.default} alt="Filter" />
                                    </Button>
                                </div>
                            </Box>
                            {isToggled &&
                                <>
                                    <div style={webStyle.filterContainer}>
                                        <FormControl component="fieldset" style={{ width: "100%", display: "block" }}>
                                            <RadioGroup
                                                test-id="radioButton"
                                                value={selectedFilter}
                                                onChange={this.handleFilterChange}>
                                                <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                                    {["All", "Organization", "Date"].map((label, index) => (
                                                        <li style={{ borderBottom: index == 2 ? "none" : "1px solid #ddd", padding: "5px" }}>
                                                            <FormControlLabel
                                                                key={label}
                                                                value={label}
                                                                label={label}
                                                                control={<BpRadio />}
                                                                style={{ marginLeft: "1px" }}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    {selectedFilter == "Date" && (
                                        <Box sx={{ marginTop: "10px", width: "100%", gap: "10px" }}>
                                            <TextField
                                                data-testid="selected-date-input"
                                                variant="outlined"
                                                value={selectedDate && selectedDate.toLocaleDateString()}
                                                sx={webStyle.inputRoot}
                                            />
                                            <DatePickerComp >
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    inline
                                                />
                                            </DatePickerComp>
                                        </Box>
                                    )}

                                    {selectedFilter == "Organization" &&
                                        <Box sx={{ marginTop: "10px" }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="dropdown-label"
                                                    data-test-id="selected-service-input"
                                                    value={selectedOrganization.attributes?.organization_name}
                                                    onClick={this.handleOrgToggle}
                                                    open={false}
                                                    displayEmpty
                                                    renderValue={()=>this.display()}
                                                    sx={webStyle.serviceSelect}
                                                >
                                                </Select>
                                            </FormControl>
                                            {isOrgOpen && (
                                                <Paper sx={{ marginTop: "10px", borderRadius: "8px", border: "1px solid #E2E8F0", boxShadow: 2 }}>
                                                    {this.state.organizations?.map((organization:any) => (
                                                        <MenuItem
                                                            key={organization.id}
                                                            data-test-id="menuItem"
                                                            onClick={() => this.handleOrgChange(organization)}
                                                            sx={webStyle.menuItem}>
                                                            <Typography sx={webStyle.label}>{organization.attributes?.organization_name}</Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Paper>
                                            )}
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                        <Divider sx={{ margin:"8% 0", borderBottom: "2px solid #D9D9D9" }} />
                        <Box>
        {this.state.referrals?.map((item, index) => (
           <React.Fragment key={index}>{this.renderReferral(item)}</React.Fragment>
        ))}
      </Box>
          <Box>
            {this.renderModal()}
            {this.renderSuccessModal()}
          </Box>

   </Container>
 </View>
</NavigationMenu>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
  },
  'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      ...theme.applyStyles('dark', {
          backgroundColor: '#30404d',
      }),
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
      ...theme.applyStyles('dark', {
          background: 'rgba(57,75,89,.5)',
      }),
  },
  ...theme.applyStyles('dark', {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
      backgroundColor: '#394b59',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#CF9500',
  backgroundImage: 'linear-gradient(180deg, hsla(45, 100%, 50%, 0.1), hsla(45, 100%, 60%, 0))',
  '&::before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#CF9500',
  },
});

const DatePickerComp = styled(Box)(({ theme }) => ({
  ".react-datepicker": {
      width: "100%",
      maxWidth: "100%",
      border: "1px solid #E2E8F0",
      borderRadius: "8px",
  },
  ".react-datepicker__month-container": {
      width: "100%",
      maxWidth: "100%",
  },
  '.react-datepicker-wrapper': {
      width: "100%",
  },
  ".react-datepicker__input-container": {
      width: "100%",
  },
  ".react-datepicker__day-names": {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      width: "100%",
      textAlign: "center",
      margin: "0.4rem",
  },
  ".react-datepicker__week": {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      width: "100%",
      textAlign: "center",
      margin: "0",
      padding: "0",
  },
  ".react-datepicker__input-container input": {
      width: "100%",
      padding: "15px",
      borderRadius: "5px",
      outline: "none",
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderStyle: "solid",
  },
  ".react-datepicker__header": {
      textAlign: "center",
      backgroundColor: "#fff",
      borderBottom: "none",
      padding: "17px 0 0 0",
      position: "relative",
  },
  ".react-datepicker__day": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5em",
      width: "100%",
      maxWidth: "100%",
      margin: "0",
  },
  ".react-datepicker__day--selected": {
      backgroundColor: "#FFFDE7",
      border: "1px solid #FFEC42",
      borderRadius: "20%",
      color: "#333",
  },
}))
const webStyle = {
  selectedTitle:{marginLeft:1,margin:1,color:"#2D2A24",fontWeight:"400",fontSize:"16px",font:"Inter"},
    selectedValue:{marginLeft:2,marginBottom:1,color:"#565047",fontWeight:"700",fontSize:"16px",font:"Inter"},
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 400,
    // margin:"32px 0 8px 0"
    
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
container: {
  flex: 1,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "20px",
  Padding:"10px",
  alignItems: "center",
  // backgroundColor: "#fff",
},
goback: {
  height: "22px",
  width: "80px",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  marginBottom: "10px",
},
arrowIcon: {
  fontSize: "14px",
  fontWeight: 400,
  color: "#2D2A24"
},
goBackText: {
  color: "#1D4ED8",
  fontSize: "14px",
  fontWeight: 400,
},
heading: {
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 700,
  // fontFamily: "Inter",
  marginBottom: "10px"
},
subHeading: {
  // fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "6px"
},
mainBox: {
  background: "#FFFFFF",
  borderRadius: "10px",
  padding: "10px 20px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
},
filterBox: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
},
filterText: {
  flex: 1,
  fontWeight: "700",
  fontSize: "16px",
  font: "Inter"
},
filterButton: {
  border: "1px solid #CBD5E1",
  height: "45px",
  minWidth: '45px',
  borderRadius: "8px",
  boxShadow: '0px 2px 8px 0px #00000014',
},
filterContainer: {
  border: "1px solid #CBD5E1",
  borderRadius: 8,
  marginTop: "5px"
},
services: {
  borderRadius: "8px",
  backgroundColor: "#fff",
},
serviceSelect: {
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  "&.Mui-focused": {
      border: "1px solid #CBD5E1",
  },
  "&:hover": {
      border: "1px solid #CBD5E1",
  }
},
menuItem: {
  padding: "10px",
  borderBottom: "1px solid #E2E8F0",
  "&.Mui-selected": {
      backgroundColor: "#fffde7 !important",
  },
  "&:last-child": { borderBottom: "none" },
  "&:focus": {
      backgroundColor: "#fffde7",
      outline: "none",
  },
  "&:active": {
      backgroundColor: "#fffde7",
  },
},
label: {
  color: "#2D2A24",
  fontSize: "12px",
  fontWeight: 700,
},
select: {
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  padding: "8px",
  "& .MuiSelect-icon": {
      fontSize: "20px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
  },
  "&:hover": {
      backgroundColor: "#f9f9f9",
  },
},
selectedItem: {
  backgroundColor: "#fffde7 !important",
},
inputRoot: {
  width: "100%",
  borderRadius: "8px",
  marginBottom: "10px",
  '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CBD5E1',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF787',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF787',
      },
  },
},
cardBoxStyle: {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 1,
  marginBottom: 2,
  borderRadius: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
},
refHeading:{
  fontWeight:700,
  fontSize:"14px",
  color:"#2D2A24"
  },
  refSub:{
  fontWeight:400,
  fontSize:"12px",
  color:"#2D2A24"
  },
  title: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#2D2A24"
  },
  subTitle1: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#2D2A24"
  },
  modal:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor:"#F6F6F3",
    padding:2,
    maxHeight: "80vh", 
    width:"80vw",
    maxWidth:"320px",
    borderRadius:"8px",
    boxShadow: 24,
    overflowY: 'auto', 
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
    },
  commonBtn: {
    backgroundColor: "#F5C502",
    color: '#2D2A24',
    height: "44px",
    fontWeight: "700",
    borderRadius: "8px",
    textTransform:"none",                    
    '&:hover': {
      backgroundColor: '#F5C502',
    },
    '&:active': {
      backgroundColor: '#F5C502',
    },
  },
  
btnStyle: {
  width: "48px",
  height: "48px",
  backgroundColor: "#FCE38B",
  color: "black",
  borderRadius: "8px",
  '&:active': {
      backgroundColor: '#FCE38B',
    },
  '&:hover': {
    backgroundColor: '#FCE38B',
  },
},
warningBox : {
  backgroundColor: '#FEF3C7',
  color: '#D97706',
  padding: '10px 16px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  borderLeft: '4px solid #D97706',
  marginBottom: theme.spacing(3),  
},
};
// Customizable Area End
