import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface Service{
  [x: string]: unknown;
  name: any;
  checked: boolean | undefined;
  id: string,
  type: string,
  checkedIds:boolean,
  attributes: {
      sub_services: any;
      name: string,
      service_offered_name: string,
      created_at: string,
      updated_at: string,
      checked: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Item {
  id: number;
  name: string;
  input_type:string;
  count:number | string
}
interface Org{
  
    data: {
        id: string,
        type: string,
        attributes: {
            organization_name: string,
            address: string,
            contact_person:string,
            organization_phone: string,
            organization_email: string
        }
    },
    relationships: {
        sub_services: {
            data: []
        }  }

}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedService: string;
  allServices:Service[],
  token:string,
  openReserveBed:boolean,
  selectedBedService:Service[],
  selectedItems: string[];
  options:string,
  orgnizationData:Org[],
  openReferral:boolean,
  selectedOrg:{data:{attributes:{organization_name:string}}},
  formId: string,
  formType: string,
  open: boolean,
  selectedFiles: File[];
  success:boolean,
  uploaded:boolean,
  subTitle:string,
  isDropdownOpen:number,
  openView:boolean,
  removeImg:string,
  imageView:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class HealthcareAccessFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllServicesCallId:string=""
  apiGetOrgCallId: string="";
  reserveBedCallId: string="";
  apiCallIdGetHealthcareFormDetails: string = "";
  uploadImageCallIdHarm: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedService: "",
  
    allServices:[],
    token:"",
    openReserveBed:false,
    selectedBedService:[],
    selectedItems:[],
    options:"",
    orgnizationData:[],
    openReferral:true,
    selectedOrg:{data:{attributes:{organization_name:""}}},
    formId: "",
    formType: "",
    open: false,
    selectedFiles: [],
    success:false,
    uploaded:false,
    subTitle:"",
    isDropdownOpen:0,
    openView:false,
    removeImg:"",
    imageView:""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

 

  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      if (apiRequestCallId ===  this.apiGetAllServicesCallId) {
      
        
          
          this.setState({allServices:responseJson.data,},()=>this.addCheckedFieldToSubServices())
        
        
        
    }
      if   (apiRequestCallId ===  this.apiGetOrgCallId){
        this.setState({orgnizationData:responseJson})
      }
      if(apiRequestCallId === this.apiCallIdGetHealthcareFormDetails) {
        console.log("popppppppppp44444", responseJson)
      }
      if( this.uploadImageCallIdHarm){
            this.handleImageUploadHarm(responseJson);
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const formid = await getStorageData("formId");
    const formtype = await getStorageData("formType");
    this.setState({
      formId: formid,
      formType: formtype
    });
    this.getFormDetails()
  }
  getFormDetails = async () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
       token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetHealthcareFormDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.apiCallIdGetHealthcareFormDetails;
};

  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getAllServices(),)
  };
  getAllServices = () => {
    this.getOrganiszationAll()
    const header = {
      token:this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllServicesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllServices
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
 
  
  addCheckedFieldToSubServices() {

this.state.allServices.forEach((service) => {
  service.attributes.sub_services.forEach((subService: { checked: boolean; }) => {
      subService.checked = false; // Default value for checked
  });
});
  }
  handleToggle = (selectedService: Service, selectedSubService: Service) => {
  

    if (selectedSubService.name === "Rehabilitation Center") {
        const selectedItems = this.state.allServices
            ?.filter((item) => item.checked)
            .map((item) => item.name);

        this.setState({
            openReserveBed: true,
            selectedBedService: selectedSubService.name,
            selectedItems: selectedItems,
        });
    }
    this.setState((prevState) => ({
      allServices: prevState.allServices.map((service) => ({
          ...service,
          attributes: {
              ...service.attributes,
              sub_services: service.attributes.sub_services.map((subservice: { id: number; }) => ({
                  ...subservice,
                  checked: subservice.id === parseInt(selectedSubService.id)?true:false,
              })),
          },
      })),
  }));
};
getOrganiszationAll = () => {
   
  const header = {
    token:this.state.token
  }



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetOrgCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getOrgApiENdPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodapi
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};

handleOpenHarm = () => {
  this.setState(
    { open: true }
  );
};

handleCloseHarm = () => 
  {
  this.setState(
    { open: false }
  );
};

handleFileChangeHarm = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
  const files = event.target.files;
  if (files) {
    this.setState((prevState) => (
      {
      selectedFiles: [...prevState.selectedFiles, ...Array.from(files)],
    }
  ));
  }
};

uploadImagesHarm = () => {
  const { selectedFiles } = this.state;
  const formData = new FormData();
  selectedFiles.forEach((file) => 
    {
    formData.append(
      "blob[]", new File([file as any], file.name, { type: "image/png" })
    ); 
  });
  const headersHarm = { token: this.state.token, };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.uploadImageCallIdHarm = requestMessage.messageId;
  requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addPictureEndPoint
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headersHarm)
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage), formData
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.formAPiMethod );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleImageUploadHarm = (responseJson:{message:string,blobs:any}) => {
    if (responseJson && responseJson.message === "Images uploaded successfully") {  
      const uploadedBlob1 = responseJson.blobs;
      const uploadedImageId1 = responseJson.blobs.map((blob: { id: any; }) => blob.id)

      this.setState((prevState) => ({
        ...prevState,
        image_ids:  uploadedImageId1,
        selectedFiles: prevState.selectedFiles.map((file) =>
          file.name === uploadedBlob1.filename ? { ...file, status: "uploaded" } : file
        ) 
      }));
      this.setState({selectedFiles:[],success:true,subTitle:"Image upload is successful",uploaded:true})
      setTimeout(() => {
        this.setState({ success: false }); 
      }, 2000);
    } 
    

  };

  goback=async (page:string)=>{
    await removeStorageData("showEdit")
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  viewImageHarm(file: File,index:number){
    this.setState({isDropdownOpen:index+1})
    const reader = new FileReader();
      reader.onload = () => {
         this.setState({imageView:reader.result as string})
      };
      reader.readAsDataURL(file); 
  }
  handleCloseHarmView = () => {
    this.setState({openView:false,success:false, isDropdownOpen:0, removeImg:"" });
  };
  handleRemoveFile = (index: number) => {
    this.setState({isDropdownOpen:0, openView:false,removeImg:"",imageView:""})
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.filter((_, i) => i !== index),
    
    }));
  };

  // Customizable Area End
}
