import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography, Box, Button, FormControl, Radio, FormControlLabel, RadioGroup, InputLabel, Select, Divider,
    TextField,
    Checkbox,
    MenuItem,
    Input,
  } from "@mui/material";
  import { GoogleMap, Marker, OverlayView } from '@react-google-maps/api';

  import { styled } from '@mui/material/styles';
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { View, Dimensions, Platform } from "react-native";
  import { filterIcon, searchIcon, leftIcon } from "./assets";
  import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu";

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '25%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    border: "1px solid #64748B",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      ...theme.applyStyles('dark', {
        backgroundColor: '#30404d',
      }),
    },
    ...theme.applyStyles('dark', {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
      backgroundColor: '#394b59',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
  }));

  const BpCheckedIcon = styled(BpIcon)({
    border: "1px solid #CF9500",
    boxShadow: "none",
    backgroundColor: '#CF9500',
    backgroundImage: "none",
    borderRadius: '6px',
    width: '24px',
    height: '24px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '45%',
      left: '45%',
      width: '6px',
      height: '12px',
      border: 'solid #fff',
      borderWidth: '0 3px 3px 0',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      borderRadius: '2px',
    },
    'input:hover ~ &': {
      backgroundColor: '#CF9500',
    },
  });




  const DatePickerComp = styled(Box)(({ theme }) => ({
    ".react-datepicker": {
    width: "100%", 
    maxWidth: "100%",
    height:"100%"
  },
  ".react-datepicker__month-container": {
    width: "100%", 
    maxWidth: "100%", height:"100%"
  },
    '.react-datepicker-wrapper': {
      width: "100%",height:"100%",backgroundColor:"red"
    },
    ".react-datepicker__input-container": {
      width: "100%",height:"100%",backgroundColor:"red"
    },
    ".react-datepicker__day-names": {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)", 
      width: "100%",
      textAlign: "center",
      margin:"0.4rem",
    },
    ".react-datepicker__week": {
      display: "grid", 
      gridTemplateColumns: "repeat(7, 1fr)",
      width: "100%",
      textAlign: "center",
      margin:"0",
      padding:"0",
    },
    ".react-datepicker__input-container input": {
      width: "100%",
      padding: "15px",
      borderRadius: "5px",
      outline: "none",
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    ".react-datepicker__header": {
      textAlign: "center",
      backgroundColor: "#fff",
      borderBottom: "none",
      padding: "17px 0 0 0",
      position: "relative",
    },
    ".react-datepicker__day": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5em",
      width: "100%",
      maxWidth:"100%",
      margin: "0", 
    },
    ".react-datepicker__day--in-range": {
      backgroundColor: "#FFF4DB",
      color: "#000000",
      borderRadius: "0", 
      border: "none",
    },
    ".react-datepicker__day--range-start": {
      backgroundColor: "#FFF4DB", 
      color: "#000000",
      borderRadius: "25% 0 0 25%",
    },
    ".react-datepicker__day--range-end": {
      backgroundColor: "#FFF4DB", 
      color: "#000000",
      borderRadius: "0 25% 25% 0",
    },
    ".react-datepicker__day--selected": {
      backgroundColor: "#FFF4DB",
      color: "#000000",
      borderRadius: "50%", 
      border: "none",
    },
  }));
  const containerStyle = {
    width: '100%',
    height: '400px',
  };
  
  // Center the map around the first data point or a default location
  const defaultCenter = {
    lat: 28.6336,
    lng: 77.2050,
  };
  
  const data = [
    {
      id: '38',
      attributes: {
        latitude: 18.516726,
        longitude:  73.856255,
        event_name: 'test',
        icon: 'https://bronxopioidcollective-569329-ruby.b569329.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBJdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--025d65deeaaca82177d688178698d0399b609775/Screenshot_2024-11-10_at_6.29.19_PM-removebg-preview.png',
      },
    },
    {
      id: '2',
      attributes: {
        latitude: 28.6336048088,
        longitude: 77.2050245803,
        event_name: 'Drug supply',
        icon: 'https://bronxopioidcollective-569329-ruby.b569329.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBJdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--025d65deeaaca82177d688178698d0399b609775/Screenshot_2024-11-10_at_6.29.19_PM-removebg-preview.png',
      },
    },
    // Add more entries...
  ];


  // Inspired by blueprintjs
  function BpRadio(props: any) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  customCircleIcon = {
    path: "M 0,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
  fillColor: "#F5C502",
  fillOpacity: 1,
  strokeColor: "#F5C502",
  strokeOpacity: 0.1,
  strokeWeight: 40,
  scale: 1,
  // anchor: new window.google.maps.Point(10, 10)
  };
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isToggled, selectedFilter, selectedService} = this.state;

      return (
        <div>
          <NavigationMenu navigation={this.props.navigation} id={""} >
            <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
              <Grid item xs={12} md={8}>
                <View style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                  <Grid container direction="column">
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View style={{
                        display: "flex",
                        paddingTop: "20px", padding: 10
                      }}>
                        <Typography component="h1" style={{ fontSize: "14px" }} >
                          <span style={{ color: "#1D4ED8" }} onClick={() => this.props.navigation.goBack()}> <img src={leftIcon.default} alt="arow icon" width={"16px"} height={"16px"} style={{ transform: "translateY(3px)" }} /> Go Back</span>
                        </Typography>
                        <Typography sx={{
                          flex: 1,
                          padding: "10px", fontWeight: "700", fontSize: "24px", 
                        }}>
                          Maps
                        </Typography>
                        <Typography sx={{
                          flex: 1,
                          padding: "10px", fontWeight: "700", fontSize: "17px",
                        }}>
                          Outreach Workers Performance
                        </Typography>
                      </View>
                    </Grid>
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View style={{
                        display: "flex",
                        paddingTop: "20px", padding: 10
                      }}>
                        <Box sx={{ background: "#fff", borderRadius: "10px", padding: "10px 20px" }}>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography sx={{

                              flex: 1,
                              fontWeight: "700", fontSize: "16px",  
                            }}>
                              Filter:
                            </Typography>
                            <div>
                              <Button onClick={this.handleToggle} data-test-id="toggle_button" sx={{
                                border: "1px solid #CBD5E1",
                                height: "45px",
                                minWidth: '45px',
                                borderRadius: "8px",
                                boxShadow: '0px 2px 8px 0px #00000014',
                              }}>
                                <img src={filterIcon.default} alt="Filter Icon" />
                              </Button>
                            </div>
                          </Box>
                          {isToggled &&
                            <div>
                              <div style={styles.filterContainer}>
                                <FormControl component="fieldset" style={{ width: "100%", display: "block" }}>
                                  <RadioGroup
                                    test-id = "radioButton"
                                    value={selectedFilter}
                                    onChange={this.handleFilterChange}
                                  >
                                    <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                      {["All", "Team Member", "Service Type", "Date Range"].map((label) => (
                                        <li style={{ borderBottom: "1px solid #ddd", padding: "5px", marginLeft: "10px" }}> <FormControlLabel
                                          key={label}
                                          value={label}
                                          control={<BpRadio />}
                                          label={label}
                                        /></li>
                                      ))}
                                    </ul>
                                  </RadioGroup>
                                </FormControl>
                              </div>
                             
                              {selectedFilter === 'Service Type' && (
                                
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                <Select
                                  sx={[styles.selectMenu,{ whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%", 
                                    display: "block",
                                    "@media (max-width: 300px)": { maxWidth: "70%" }, 
                                    }]} 
                                  value={this.state.selectedService}
                                  renderValue={this.renderValue}
                                  displayEmpty
                                 
                                  >
                                  {this.state.serviceList?.map((item, index) => (
                                     <MenuItem key={item.service_type_name} value={item.service_type_name}
                                     style={{borderBottom:  '1px solid #CBD5E1',
                                      
                                     }}
                                   >
                                    <Box data-test-id="checkBoxId" sx={[styles.itemsMainBox,{ marginLeft:3,}]} key={item.service_type_name} onClick={() => this.handleService(item,index)}>
                                    <Box sx={[styles.checkboxContainer,]}>
                                      <Checkbox
                                        edge="start"
                                        checked={item.checked}
                                        tabIndex={-1}
                                        disableRipple
                                       
                                        sx={{
                                          color: "#64748B",width:"10%",
                                          borderRadius: "16px",
                                          
                                          '&.Mui-checked': {
                                            color: "#CF9500",
                                          },
                                        }}
                                      />
                                      <Typography sx={{ textAlign: "left",fontSize:"12px", whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",}} style={styles.listItemName}>{item.service_type_name}</Typography>
                                    </Box>
                                   
                                  
                                      
                                  </Box>
                                  </MenuItem>
                                  
                                  ))}
                                </Select>
                              </FormControl>
                          
                              )}
                                {selectedFilter === 'Team Member' && (
                                
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                <Select
                                  sx={styles.selectMenu} 
                                  value={this.state.selectedTeamMember} displayEmpty
                                  renderValue={this.renderValueTeam}
                                >
                                    {this.state.teamMember?.map((item, index) => (
                                      <MenuItem key={item.id} value={item.id}
                                      style={{borderBottom:  '1px solid #CBD5E1'}}
                                    >
                                       <Box data-test-id="checkBoxId2" sx={[styles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={item.id} onClick={() => this.handleTeamMember(item,index)}>
                                                        <Box sx={[styles.checkboxContainer,]}>
                                                          <Checkbox
                                                            edge="start"
                                                            checked={item.checked}
                                                            tabIndex={-1}
                                                            disableRipple
                                                           
                                                            
                                                            sx={{
                                                              color: "#64748B",width:"10%",
                                                              borderRadius: "16px",
                                                              
                                                              '&.Mui-checked': {
                                                                color: "#CF9500",
                                                              },
                                                            }}
                                                          />
                                                          <Typography sx={{ textAlign: "left",fontSize:"12px"}} style={styles.listItemName}>{item.first_name} {item.last_name}</Typography>
                                                        </Box>
                                                       
                                                      
                                                          
                                                      </Box>
                                                      </MenuItem>
                                  ))}
                                  
                                </Select>
                              </FormControl>
                          
                              )}
                         {selectedFilter === "Date Range" && (
                              <>
                             
                                <Box sx={styles.dateRangeContainer}>
                                <Grid container  flexDirection={"row"}>
                                <Grid xs={4} sx={[styles.dateLabel,{alignSelf:"center"}]} item><Typography >From:</Typography></Grid>
                                <Grid  xs={8} item> <Box><DatePickerComp>
                                      <TextField
                                       data-testid="start-date-input"
                                       variant="outlined"
                                       value={this.state.startDate ? this.state.startDate.toLocaleDateString() : ""}
                                        onClick={() => this.handleActivePicker("start")}
                                        sx={styles.inputRoot}
                                      />
                                    </DatePickerComp> </Box></Grid>
                              </Grid>
                              <Grid container  flexDirection={"row"}>
                                <Grid xs={4} sx={[styles.dateLabel,{alignSelf:"center"}]} item><Typography >To:</Typography></Grid>
                                <Grid  xs={8} item>
                                  <Box>
                                <DatePickerComp>
                                    <TextField
                                    data-testid="end-date-input"
                                     variant="outlined"
                                     value={this.state.endDate ? this.state.endDate.toLocaleDateString() : ""}
                                     onClick={() => this.handleActivePicker("end")}
                                     sx={styles.inputRoot}
                                    />
                                  </DatePickerComp></Box>
                                </Grid>
                              </Grid>
                                
                                <DatePickerComp>
                                  {this.state.activePicker === "start" && (
                                     <DatePickerComp>
                                    <DatePicker
                                      selected={this.state.startDate}
                                      onChange={this.handleStartDateChange}
                                      inline
                                      maxDate={this.state.endDate}
                                    />
                                    </DatePickerComp>
                                  )}

                                  {this.state.activePicker === "end" && (
                                    <DatePicker
                                      selected={this.state.endDate}
                                      onChange={this.handleEndDateChange}
                                      inline
                                      minDate={this.state.startDate}
                                    />
                                  )}

                                  {this.state.activePicker === "dateRange" && (
                                    <DatePicker
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      inline
                                    />
                                  )}
                                </DatePickerComp>
                                </Box>
                              </>
                          )}

                            </div>
                          }
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={styles.boxstyle}>
                          <img style={{ position: "absolute", top: "17px", left: "6px" }} src={searchIcon.default} alt="search icon" />
                          <Input disableUnderline type="text" data-testid="searchField"
                          inputProps={{
                            disableUnderline: true, 
                          }}  sx={{
                          "& .MuiInputBase-input::placeholder": {
                            color: "#94A3B8", 
                            opacity: 1, 
                          },   
                        }} onChange={(text:React.ChangeEvent<HTMLInputElement>)=>this.searchFilterFunction(text?.target?.value)}
                           value={this.state.searchtext} placeholder="Search on map" style={styles.search} />
                        </Box>
                      </View>
                    </Grid>
                    <Grid item xs={8} style={{ padding: 5 }}>
                      <View>
                       
                      </View>
                      
      <GoogleMap   data-testid="map" mapContainerStyle={containerStyle} center={{
    lat: this.state.lat,
    lng: this.state.lang,
  }} 
  zoom={50}>
    
     <Marker
          position={{
            lat: this.state.lat,
            lng: this.state.lang,
          }}
          icon={this.customCircleIcon}
            title={"current location"} 
        />
        {this.state.locationList.map((entry,index) => (
        
          <OverlayView
          position={{
               lat: entry.lattiutude,
               lng: entry.longitude,
             }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
        >
          <div
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#FFFDE7", 
             width: "60px",
           height: "60px",
              backgroundSize: "cover",
           backgroundPosition: "center",
           borderRadius: "50%", 
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
            }}
          >
            <img
              src= {entry.icon_url} 
              alt="Custom Marker"
              style={{
                width: "20px",
                height: "20px",
                objectFit: "cover",
               
              }}
            />
          </div>
        </OverlayView>
        ))}
      </GoogleMap>
   
                    </Grid>
                  </Grid>
                </View>
              </Grid>
            </Grid>
          </NavigationMenu>
        </div>
      );
    }
  // Customizable Area End
}

// Customizable Area Start
  const styles = {
    boxstyle:{ position: "relative",backgroundColor:"white",borderBottom:"none",border: "1px solid #ddd",borderRadius:"8px" },
    itemsMainBox: {
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      width:"100%",
    },
    checkboxContainer: {
      justifyContent:"space-between",
      display:"flex",
      alignItems:"center",
    },
    listItemName: {
      fontWeight:700,
      fontSize:"14px",
      color:"#2D2A24",
    },
    search:{ width: "100%", borderRadius: "8px", outline: "none", border: "1px solid #ddd", padding: "14px 0 12px 38px", fontSize: "16px" },
    container: {
      flex: 1,
      padding: 16,
      marginLeft: "auto",
      marginRight: "auto",
      width: Platform.OS === "web" ? "75%" : "100%",
      maxWidth: 650,
      backgroundColor: "#ffffffff",
    },
    selectMenu: {
      height: "44px",
      color: "#94A3B8",
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "left",whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    areaText: {
      color:"#2D2A24",
      fontSize: '16px',
      fontWeight: 400,
      
    },
    map: {
      width: "100%",
      height: "400px",
      // position: "absolute",
      // top: 0,
      // bottom: 0,
    },
    main: {
      display: "flex",
      alignItems: "center"
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      width: "50%",
    },
    textFont: {
      fontFamily: "Helvetica",
    },
    formWrapper: {
      display: "flex",
      width: "100%",
      paddingTop: "20px"
    },
    inputWrapper: {
      flex: 1,
      padding: "20px"
    },
    removeBackground: {
      backgroundColor: "transparent"
    },
    inputDefaultLabel: {
      color: "#94A3B8",
      fontSize: 14,
      fontWeight: "400"
    },
    formControl: {
      backgroundColor: '#ffffff',
      height: "44px",
      marginTop: "20px"
    },
    menuItems: {
      backgroundColor: '#ffffff',
      color: "#2D2A24",
      fontSize: 14,
      fontWeight: "700",
      '&:hover': {
        background: '#FFFDE7',
      },
    },
    menuItemsSecond: {
      fontWeight: "700",
      backgroundColor: '#ffffff',
      fontSize: 14,
      color: "#2D2A24",
      '&:hover': {
        background: '#FFFDE7',
      },
    },
    checkBoxText: {
      fontSize: 18,
      color: "#6200EE",
      fontWeight: "bold",
    },
    buttonsWrapper: {
      flex: 1,
      padding: "10px",
    },
    OrWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
    },
    footerText: {
      textAlign: "center",
      margin: 5
    },
    headerLogo: {
      width: "122.32px",
      height: "28px",
      backgroundColor: "#F5C502",
      borderRadius: 13,
      marginRight: 10
    },
    filterContainer: {
      border: "1px solid #CBD5E1",
      borderRadius: 8,
      marginTop: "5px"
    },

    dateRangeContainer: {
      marginTop: "20px",
      display: "flex",
      gap: "5px",
      flexDirection: "column"
    },
    datePickerRow: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    },
    dateLabel: {
      fontSize: "16px",
      fontWeight: 400,
    },
    inputRoot: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CBD5E1', 
          width:"100%"
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '##CBD5E1',  width:"100%"
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '##CBD5E1',  width:"100%"
        },
      },
    },

  };
// Customizable Area End
