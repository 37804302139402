import React from "react";
// Customizable Area Start
import { Container, Box, Input, Button, Typography, InputAdornment, Divider, Card, CardContent, Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { brush, search, arrow } from "./assets";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { FlatList } from "react-native";
import moment from "moment";
import DatePicker from "react-datepicker";

const data = [
  { name: "Rita Hoffman", referrals: 9 },
  { name: "Rex Cobb", referrals: 5 },
  { name: "Viola Harrison", referrals: 3 },
  { name: "Wesley Buckley", referrals: 10 },
  { name: "Harding Cantrell", referrals: 4 },
];
// Customizable Area End


import ReservationsController, {
  Props,
  configJSON,
  Item,
} from "./ReservationsController";
import "@mui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { View } from "@builder/component-library";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

export default class Reservations extends ReservationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getToken()
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={""}>
        <View style={{ backgroundColor: "#f3f3f1" }}>
          <Container maxWidth="md" sx={{padding:"3% 5%"}}>
            <Box sx={webStyle.container}>
              <Box onClick={this.handleGoBack} sx={webStyle.gobackbtn} data-test-id="gobackBtn">
                <ArrowBackIosIcon sx={webStyle.arrowIcon} />
                <Typography sx={webStyle.goBackMsg}>Go Back</Typography>
              </Box>
              <Typography variant="h6" sx={webStyle.refHeading}>Referrals</Typography>
              <Typography variant="h6" sx={webStyle.subHeading}>Outreach Workers Performance</Typography>
            </Box>
            <Box>
              <View style={webStyle.input}>
                <Input
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", paddingTop: 1
                  }}
                  disableUnderline
                  data-test-id="txtInputSearch"
                  type={'text'}
                  placeholder={"Search"}
                  onChange={(text: React.ChangeEvent<HTMLInputElement>) => this.searchFilterFunction(text.target.value)}
                  value={this.state.searchText}
                  startAdornment={
                    <InputAdornment sx={{ marginBottom: 0.5 }} position="end">
                      <img style={{ height: "21.01px", marginRight: 8 }} src={search.default} />
                    </InputAdornment>
                  }
                />
                <img data-test-id="imageClick" onClick={() => this.clear()} style={{ height: "21.01px", marginRight: 5 }} src={brush.default} />
              </View>
              <Divider sx={{ borderBottom: "2px solid #D9D9D9",margin:"8% 0" }} />
              <Box>
                {this.state.referrals.map((item, index) => (
                  <Card
                    key={index}
                    sx={webStyle.cardStyle}>
                    <CardContent>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6" fontWeight="bold">
                          {item.worker_name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 50,
                            height: 50,
                            backgroundColor: "#FFD700",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            {item.reserve_beds_count < 10 ? `0${item.reserve_beds_count}` : item.reserve_beds_count}
                          </Typography>
                        </Box>
                        <Typography variant="body1">Referrals</Typography>
                      </Box>
                    </CardContent>
                    <Grid data-test-id="openModalButton" item xs={2}>
           <div style={{height:"48px",width:"48px", backgroundColor:"#FCE38B",borderRadius:"8px",textAlign:"center",verticalAlign:"middle",marginRight:6}}> 
              <TrendingFlatIcon data-test-id="referralBtn" onClick={() => this.gotoPage("ReferralItems",item.my_org_workers_id)} sx={{fontSize:"24px",fill:"black",verticalAlign:"middle",marginTop:"25%"}}/></div>
      
         </Grid>
                  </Card>
                ))}
              </Box>
            </Box>
          </Container>
        </View>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    alignItems: "center",
    // backgroundColor: "#fff",
  },
  arrowIcon: {
    fontSize: "14px",
    color: "#2D2A24"
  },
  gobackbtn: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    height: "22px",
    width: "80px",
    marginBottom: "10px",
  },
  goBackMsg: {
    color: "#1D4ED8",
    fontSize: "14px",
    fontWeight: 400,
  },
  refHeading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    // fontFamily: "Inter",
    marginBottom: "10px"
  },
  subHeading: {
    fontWeight: 700,
    // fontFamily: "Inter",
    fontSize: "18px",
    marginBottom: "6px"
  },
  input: {
    flexDirection: "row", justifyContent: "space-between", shadowColor: '#CBD5E1',
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    elevation: 8,
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 10, alignContent: "center", alignItems: "center", padding: 2, backgroundColor: "white"
  },
  mainBox: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "10px 20px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
  },
  cardStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 1,
    marginBottom: 2,
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  buttonStyle: {
    width: "48px",
  height: "48px",
  backgroundColor: "#FCE38B",
  color: "black",
  borderRadius: "8px",
  '&:active': {
      backgroundColor: '#FCE38B',
    },
  '&:hover': {
    backgroundColor: '#FCE38B',
  },
  },
};

// Customizable Area End