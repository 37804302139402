// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Item {
// Customizable Area Start
  id: string;
  type: string;
  attributes: {
    id: string;
    price: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    created_at: string;
    updated_at: string;
    booking_status: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string | null;
    service_name: string;
  // Customizable Area End
  };
}
// Customizable Area Start
interface RefDetails{
  id: string
type: string
attributes:  {
    organization_id: number
    organization_name: string
    sub_service_id: number
    sub_service_name: string
    status: string
    created_at: string
    organization_info:  {
        id: string
        type: string
        attributes:  {
            organization_name: string
            address: string
            contact_person: string
            organization_phone: string
            organization_email: string
            created_at: string
            updated_at: string
        }
    }
    worker_id: number
}
}
interface Org{
  
    id: string;
    attributes:{
      organization_name:string,
      id:number
      organization_id:number,
      organization_info:{attributes:{
        address:string,contact_person:string,organization_phone:string,organization_email:string
      }}
    
  }
}
export interface HistoryItem {
  id: string;
  type: string;
  attributes: {
    id: string;
    order_status: string;
    total_price: number;
    placed_date_time: string;
    created_at: string;
    updated_at: string;
    price?: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    booking_status?: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string | null;
    service_name: string;
    reservation_service: {
      id: string;
      price: number;
      created_at: string;
      updated_at: string;
      city: string;
      full_address: string;
      reservation_date: string;
      state: string;
      zip_code: number;
      service_name: string;
      booking_status: string;
      slot_start_time: string;
      slot_end_time: string;
    },
    reservation_service_image: string
  }
}
export interface ReferralItem {
  id: string
type: string
attributes:  {
    organization_id: number
    organization_name: string
    sub_service_id: number
    sub_service_name: string
    status: string
    created_at: string
    updated_at:string
    worker_id: number
}
}
interface OrgDetails{
  organization:{
    id:string,
    attributes:{
      organization_name:string,
      contact_person:string,
      organization_phone:string,
      address:string
    }
  },
  is_bed_availble:boolean
}
interface BedOrg{
  id: number,
  organization_name: string,
  address: string
  contact_person: string
  organization_phone: string
  organization_email: string,
  created_at: string
  updated_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface SProps {
// Customizable Area Start
  itemsList: Item[];
  isEditModalVisible: boolean;
  isCancelModalVisible: boolean;
  selectedItem: Item | null;
  itemData: Item | null;
  bookingStatus: string;
  isToggled:boolean;
  selectedFilter:string;
  selectedOrganization:Org;
  selectedDate:Date | null;
  isOrgOpen:boolean;
  selectedStatus:string;
  referrals:ReferralItem[];
  isModalOpen:boolean;
  cancellationReason:string;
  modalType:string;
  successModal:boolean;
  title:string;
  subTitle:string,
  rowData:number,
  token:string,
  organizations:Org[],
  selectedReferral:RefDetails,
  success:boolean,
  selectedRefOrg:BedOrg,
  bedOrgList:BedOrg[],
  refOrgDetails:OrgDetails,
  updateRef:boolean
// Customizable Area End
}

interface SSProps {
 // Customizable Area Start
  id: string;
// Customizable Area End
}

export default class ReservationsItemController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
 // Customizable Area Start
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getProductItemListApiCallId: string = "";
  createReservationApiCallId: string = '';
  deleteReservationAPiCallId: string = "";
  editReservationApiCallId: string = "";
  itemData: HistoryItem = {
    id: "",
    type: "",
    attributes: {
      id: "",
      order_status: "",
      total_price: 0,
      placed_date_time: "",
      created_at: "",
      updated_at: "",
      price: 0,
      state: "",
      full_address: "",
      city: "",
      reservation_date: "",
      zip_code: 0,
      booking_status: "",
      slot_start_time: "",
      slot_end_time: "",
      image: "",
      service_name: "",
      reservation_service: {
        id: "",
        price: 0,
        created_at: "",
        updated_at: "",
        city: "",
        full_address: "",
        reservation_date: "",
        state: "",
        zip_code: 0,
        service_name: "",
        booking_status: "",
        slot_start_time: "",
        slot_end_time: "",
      },
      reservation_service_image: "",    }
  };
  type: string = "";
  apiGetOrgCallId: string="";
  apiGetRefDetailsCallId: string="";
  apiCancelreferralCallId: string="";
  apiConfirmreferralCallId: string="";
  apiGetBedOrgCallId: string="";
  apiGetreferralORGDetailCallId: string="";
  apiUpdatereferralCallId: string="";
 // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
 // Customizable Area Start
 this.display = this.display.bind(this);
 // Customizable Area End
    this.subScribedMessages = [
 // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
// Customizable Area End
    ];

    this.state = {
  // Customizable Area Start
      itemsList: [],
      isEditModalVisible: false,
      isCancelModalVisible: false,
      selectedItem: null,
      itemData: null,
      bookingStatus: "",
      isToggled:false,
      selectedFilter:"",
      selectedOrganization:{
      
          id: "",
          attributes: {
            id:0,
            organization_name: "",
            organization_id:0,
            organization_info: {
              attributes: {
                address: "", contact_person: "", organization_phone: "", organization_email: ""
              }
            },
          }
        
       
      },
      selectedDate:new Date(),
      isOrgOpen:false,
      selectedStatus: "All Referrals",
      referrals: [
      ],
      isModalOpen:false,
      cancellationReason:"",
      modalType:"",
      successModal :false,
      title:"",
      subTitle:"",
      rowData:0,
      token:"",
      organizations:[],
      selectedReferral:{
        id: "",
        type: "",
        attributes: {
          organization_id: 0,
          organization_name: "",
          sub_service_id: 0,
          sub_service_name: "",
          status: "",
          created_at: "",
          organization_info: {
            id: "",
            type: "",
            attributes: {
              organization_name: "",
              address: "",
              contact_person: "",
              organization_phone: "",
              organization_email: "",
              created_at: "",
              updated_at: ""
            }
          },
          worker_id: 0
        },
       
      },
      success:false,
      selectedRefOrg:{
        id: 0,
        organization_name: "",
        address: "",
        contact_person: "",
        organization_phone: "",
        organization_email: "",
        created_at: "",
        updated_at: ""
      },
      bedOrgList:[],
      refOrgDetails:{
        organization: {
          id: "",
          attributes: {
            organization_name: "",
            contact_person: "",
            organization_phone: "",
            address: ""
          }
        },
        is_bed_availble: false,
        
      },
      updateRef:false
 // Customizable Area End
    };
 // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
// Customizable Area End
  }

  async receive(from: string, message: Message) {
 // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
			  "rowData"
			);
			this.setState({ rowData: data1},()=> this.getToken());

    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.createReservationApiCallId:
          this.props.navigation.navigate("ReservationHistory");
          break;
    
        case this.deleteReservationAPiCallId:
          this.setState({ isCancelModalVisible: false, selectedItem: null }, () => {
            this.props.navigation.navigate("ReservationHistory");
          });
          break;
    
        case this.getProductItemListApiCallId:
          this.setState({ referrals: responseJson.data });
          break;
    
        case this.editReservationApiCallId:
          this.setState({ isEditModalVisible: false, selectedItem: null }, () => {
            this.props.navigation.navigate("ReservationHistory");
          });
          break;
    
        case this.apiGetOrgCallId:
          this.getsearchOrg(responseJson);
          break;
    
        case this.apiGetRefDetailsCallId:
          this.setState(
            { selectedReferral: responseJson.data, isModalOpen: true },
            () => this.getBedOrglist()
          );
          break;
    
        case this.apiCancelreferralCallId:
          this.handleCancelReferral(responseJson);
          break;
    
        case this.apiConfirmreferralCallId:
          this.handleConfirm(responseJson);
          break;
    
        case this.apiGetBedOrgCallId:
          this.setState({
            bedOrgList: responseJson.organizations,
            
            updateRef: false,
            refOrgDetails: {
              organization: {
                id: "",
                attributes: {
                  organization_name: "",
                  contact_person: "",
                  organization_phone: "",
                  address: "",
                },
              },
              is_bed_availble: false,
            },
          });
          break;
    
        case this.apiGetreferralORGDetailCallId:
          this.setState({ refOrgDetails: responseJson });
          break;
    
        case this.apiUpdatereferralCallId:
          this.handleUpdateReferral(responseJson);
          break;
    
        default:
          console.warn(`Unhandled API call ID: ${apiRequestCallId}`);
          break;
      }
    }
 // Customizable Area End
    
  }
// Customizable Area Start
getsearchOrg(response: { data: any[]; }){
 
  const uniqueOrganizations: any[] = [];
const seenOrganizations = new Set();

response.data.forEach((item) => {
  const orgName = item.attributes.organization_name;
  if (!seenOrganizations.has(orgName)) {
    seenOrganizations.add(orgName);
    uniqueOrganizations.push(item);
  }
});
this.setState({  referrals:response.data,organizations: uniqueOrganizations})
}
  requestSessionData() {
    const sessionMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = sessionMsg.messageId;
    this.send(sessionMsg);
  }

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed = () => {
    const header = {
      "Content-Type": configJSON.itemListApiContentType,
      token: this.authToken
    };

    const body = {
      user_orders: [{
        reservation_service_id: this.itemData.id
      }]
    }

    const secureReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createReservationApiCallId = secureReservationMsg.messageId;

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSecureReservationList
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    secureReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.secureReservationApiMethodType
    );

    runEngine.sendMessage(secureReservationMsg.id, secureReservationMsg);
  }

  btnEditProps = {
    onPress: () => this.doButtonEditPressed()
  };
  doButtonEditPressed = () => {
    this.setState({ isEditModalVisible: true });
  }
  hideEditModal = () => {
    this.setState({ isEditModalVisible: false });
  };

  btnCancelProps = {
    onPress: () => this.doButtonCancelPressed()
  };
  doButtonCancelPressed = () => {
    this.setState({ isCancelModalVisible: true });
  }
  hideCancelModalButtonPress = () => {
    this.setState({ isCancelModalVisible: false });
  };

  cancelOrderDataRequest = () => {
    const headers = {
      "token": this.authToken
    };

    const cancelReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteReservationAPiCallId = cancelReservationMsg.messageId;

    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlCancelReservation + this.itemData.id
    );

    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cancelReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cancelReservationApiMethodType
    );

    runEngine.sendMessage(cancelReservationMsg.id, cancelReservationMsg);
  }
  fetchItemList = () => {
    const { selectedFilter, selectedDate, selectedOrganization,selectedStatus } = this.state;
    let endpointUrl = configJSON.getReferralLstFilter;

    let queryParams: string[] = [];
    if (selectedFilter === 'Date' && selectedDate) {
     const formattedDate = selectedDate.toLocaleDateString('en-GB'); 
     queryParams.push(`date=${formattedDate}`);
    }
    if (selectedStatus==='Enrolled'|| 'Updated'|| 'Canceled' ) {
      queryParams.push(`status=${selectedStatus.toLowerCase()}`);
     }

    if (selectedFilter === 'Organization' && selectedOrganization) {
     queryParams.push(`organization_id=${selectedOrganization.attributes?.organization_id}`);
    }
    if  (selectedFilter === 'All') {
      endpointUrl += `worker_id=${this.state.rowData}`;
     }
    if (queryParams.length > 0 && selectedStatus==="All Referrals") {
     endpointUrl += `filter_by=${selectedFilter.toLowerCase()}&` + queryParams.join('&')+`&worker_id=${this.state.rowData}`;
   }
   else{
    endpointUrl += `filter_by=status&` + queryParams.join('&')+`&worker_id=${this.state.rowData}`;
   }
    const headers = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.state.token
    };

    const getItemListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductItemListApiCallId = getItemListMsg.messageId;

    getItemListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointUrl
    );

    getItemListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getItemListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getItemListMsg.id, getItemListMsg);
    return true;
  }

  handleSelectNewItem = (item: Item) => {
    this.setState({ selectedItem: item })
  }

  updateReservation = () => {
    const headers = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.authToken
    };

    const body = {
      user_order: {
        "reservation_service_id": this.state.selectedItem?.id
      }
    }

    const updateReservationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editReservationApiCallId = updateReservationMsg.messageId;

    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlEditReservation + this.itemData.id
    );

    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    updateReservationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editReservationApiMethodType
    );

    runEngine.sendMessage(updateReservationMsg.id, updateReservationMsg);
  }
  handleGoBack = () =>{
    window.history.back();
  }
  handleTabClick = (selectedStatus:string) => {
    this.setState({ selectedStatus: selectedStatus},()=>this.fetchItemList());
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }

handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.getOrganiszationAll()
this.setState({ selectedFilter: event.target.value,selectedOrganization:{
  id: "",
  attributes: {
    organization_name: "",
    id: 0,
    organization_id: 0,
    organization_info: {
      attributes: {
        address: "",
        contact_person: "",
        organization_phone: "",
        organization_email: ""
      }
    }
  }
} });
};

handleOrgToggle = () => {
    this.setState((prevState) => ({
      isOrgOpen: !prevState.isOrgOpen,
    }));
  }

handleOrgChange = ( value: Org) =>{
  console.log(value,"oooo")
    this.setState({ selectedOrganization: value,isOrgOpen:false },()=>this.fetchItemList());
  };

handleDateChange = (date: Date | null):void => {
    this.setState({ selectedDate: date },()=>this.fetchItemList());
  };

handleOpen = (referral: RefDetails) => {
  this.setState({ isModalOpen: true });
};

handleClose = () => {
  this.setState({ isModalOpen: false,modalType:"",successModal:false,updateRef:false,selectedRefOrg:{
    id: 0,
    organization_name: "",
    address: "",
    contact_person: "",
    organization_phone: "",
    organization_email: "",
    created_at: "",
    updated_at: ""
  },refOrgDetails:{
    organization: {
      id: "",
      attributes: {
        organization_name: "",
        contact_person: "",
        organization_phone: "",
        address: ""
      }
    },
    is_bed_availble: false
  }});

};
handleConfirm = (response: {
  errors: [{message:string}]; message: string; 
}) => {
  if(response.message){
    this.setState({title:"Confirm Referral",subTitle:response.message,success:true,successModal:true,isModalOpen:false,modalType:""})
  }else{
    this.setState({title:"Confirm Referral",subTitle:response.errors[0].message,successModal:true,success:false,isModalOpen:false,modalType:""})
  }
 this.fetchItemList()
}
handleChangeModal = (modalType:string) =>{
  this.setState({modalType})
}
handleCancelReferral = (response: {
  errors: [{message:string}]; message: string; 
}) =>{
  // this.setState({title,subTitle,successModal:true,isModalOpen:false,modalType:""})
  if(response.message){
    this.setState({title:"Cancel Referral",subTitle:response.message,success:true,successModal:true,isModalOpen:false,modalType:""})
  }else{
    this.setState({title:"Cancel Referral",subTitle:response.errors[0].message,successModal:true,success:false,isModalOpen:false,modalType:""})
  }
 this.fetchItemList()
}
handleUpdateReferral = (response: {
  errors: [{message:string}]; message: string; 
}) => {
  if(response.message){
    this.setState({title:"Edit Referral",subTitle:response.message,success:true,successModal:true,isModalOpen:false,modalType:""})
  }else{
    this.setState({title:"Edit Referral",subTitle:response.errors[0].message,successModal:true,success:false,isModalOpen:false,modalType:""})
  }
 this.fetchItemList()
}
getToken = async () => {
  const token = await getStorageData("token")
  this.setState({token:token},()=>this.getOrganiszationAll())
};
getOrganiszationAll = () => {
   this.fetchItemList()
  const header = {
    token:this.state.token
  }


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetOrgCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.urlGetItemList+ `worker_id=${this.state.rowData}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.itemListApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
getReferralDetail = (ref: ReferralItem) => {
 
  const header = {
    token:this.state.token
  }



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetRefDetailsCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getReferralDetailApiENdPoint+ref.id+"?worker_id="+ref.attributes?.worker_id
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.itemListApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
cancelReferral=()=>{
   
  const header = {
    token:this.state.token
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCancelreferralCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.cancelReferralApiEndpoint+"worker_id="+this.state.selectedReferral.attributes.worker_id+"&id="+this.state.selectedReferral.id+"&cancellation_reason="+this.state.cancellationReason
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editReservationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
confirmReferral=()=>{
   
  const header = {
    token:this.state.token
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiConfirmreferralCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.confirmReferralApiEndpoint+this.state.selectedReferral.id+"&worker_id="+this.state.rowData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editReservationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
getBedOrglist = () => {
  
  const header = {
    token:this.state.token
  }



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetBedOrgCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getBedOrgApiENdPoint+this.state.selectedReferral.attributes.sub_service_id
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.itemListApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
handleSelectChange = (event: BedOrg) => {
      console.log(event,"ppppp")
  this.setState({ selectedRefOrg:event },()=>this.getBedAvaialbility());
};
getBedAvaialbility= () => {
  console.log()
  const header = {
    token:this.state.token
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetreferralORGDetailCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getBedData+this.state.selectedReferral.attributes.sub_service_id+"&id="+this.state.selectedRefOrg.id
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.itemListApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
updateReferral=()=>{
   
  const header = {
    token:this.state.token
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiUpdatereferralCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateReferralApiEndpoint+this.state.selectedReferral.id+"?organization_id="+this.state.refOrgDetails.organization.id+"&worker_id="+this.state.rowData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editReservationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};
display=()=>{
  const {  selectedOrganization } = this.state;
  if (selectedOrganization?.id?.length > 0) {
    return (
      selectedOrganization.attributes.organization_name
    );}

  return (
      "Select an Organization"
  );
}
 // Customizable Area End
}