import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface Service {
  checked: boolean;service_type_name:string,key:string
}
interface TeamMember {
  checked: boolean;
  id: number,
  first_name: string,
  last_name: string
}
interface Location {
  lattiutude: number,
  longitude: number,
  icon_url: string,
  form_type: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isToggled: boolean;
  selectedFilter: string;
  startDate:  Date | undefined;
  endDate:  Date | undefined;
  selectedService:Service[];
  selectedTeamMember:TeamMember[];
  open:boolean;
  activePicker: string;
  token:string,
  serviceList:Service[],
  teamMember:TeamMember[],
  locationList:Location[],
  lat:number,
  lang:number
  searchtext:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  
  
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isToggled: false,
      selectedFilter: 'All',
      startDate: new Date(),
      endDate: new Date(),
      selectedService: [],
      selectedTeamMember:[],
      open: false,
      activePicker:"start",
      token:"",
      serviceList:[],
      teamMember:[],
      locationList:[],
      lat:0,
      lang:0,
      searchtext:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      switch (apiRequestCallId) {
      case this.apiGetLocationCallId:
        this.setState({locationList:responseJson.data})
        break;
        case this.apiGetMemberCallId:
          this.setState({teamMember:responseJson.data},()=>this.addChecked)
          break;
          case this.apiGetServiceCallId:
            this.setState({serviceList:responseJson.data},()=>this.addCheckedService())
            break;
          case this.apiSearchCallId:
            this.setState({locationList:responseJson.data})
            break;
      default:
    
        break;
    }}
    // Customizable Area End
  }

  // Customizable Area Start
  apiGetMemberCallId: string="";
  apiGetServiceCallId: string="";
  apiGetLocationCallId: string="";
  apiSearchCallId: string="";
  async componentDidMount() {
    super.componentDidMount();
 this.getToken();
 this.getMember()
   this.getService()
   this.renderValue()
   this.renderValueTeam()

 this.getCurrentLocation()
  }
  handleToggle = () => {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }
  
  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.getMember()
   this.getService()
    this.setState({ selectedFilter: event.target.value, selectedTeamMember:[], selectedService:[], startDate:new Date(),endDate:new Date() },()=>{
      if(event.target.value==="All"){
        this.getLocation()
      }
    });
   
  };
  handleService = (event:Service,index:number) => {
   
    this.setState((prevState) => {
      const newSelectedService = event;
      const exists = prevState.selectedService.some(service => service.service_type_name === newSelectedService.service_type_name);
    
      return {
        selectedService: exists
          ? prevState.selectedService.filter(service => service.service_type_name !== newSelectedService.service_type_name)
          : [...prevState.selectedService, newSelectedService]
      };
    },()=>this.getLocation());
    // ,
    this.state.serviceList?.forEach((item, idx) => {
      if (idx === index) {
        item.checked = !item.checked; 
      }
    });
  };
  handleTeamMember = (event:TeamMember,index:number) => {
   
    this.setState((prevState) => {
      const newSelectedService = event;
      const exists = prevState.selectedTeamMember.some(service => service.id === newSelectedService.id);
    
      return {
        selectedTeamMember: exists
          ? prevState.selectedTeamMember.filter(service => service.id !== newSelectedService.id)
          : [...prevState.selectedTeamMember, newSelectedService]
      };
    },()=>this.getLocation());
    this.state.teamMember?.forEach((item, idx) => {
      if (idx === index) {
        item.checked = !item.checked; 
      }
    });
  };
  addChecked=()=>{
    this.setState(prevState => ({
      teamMember: prevState.teamMember?.map(item => ({
        ...item,
        checked: false ,
          
      
      }))
    }));
  }
  addCheckedService=()=>{
    this.setState(prevState => ({
      serviceList: prevState.serviceList?.map(item => ({
        ...item,
        checked: false ,
          
      
      }))
    }));
  }
  handleStartDateChange = (startDate: Date | null) => {
    this.setState({ startDate: startDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };
  
  handleEndDateChange = (endDate: Date | null) => {
    this.setState({ endDate: endDate ?? undefined },()=>this.getLocation()); 
    this.setState({activePicker:"dateRange"});
  };

  handleActivePicker = (val: string) =>{
    this.setState({activePicker:val})
  }
  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getLocation())
  };
  query=()=>{
    let queryParams: string[] = [];
    const {  selectedService, selectedTeamMember } = this.state;
    if (Array.isArray(selectedService) && selectedService.length > 0) {
      selectedService.forEach((service, index) => {
        if (index === 0) {
          queryParams.push(`service_type&service_type_key=${service.key}`); 
        } else {
          queryParams.push(`service_type_key=${service.key}`); 
        }
      });
    }
    if (Array.isArray(selectedTeamMember) && selectedTeamMember.length > 0) {
      selectedTeamMember.forEach((service, index) => {
        if (index === 0) {
          queryParams.push(`team_member&worker_id=${service.id}`); 
        } else {
          queryParams.push(`worker_id=${service.id}`); 
        }
      });
    }
   return queryParams
  }
  getLocation = () => {
   
    const { selectedFilter, startDate, endDate, selectedService, selectedTeamMember } = this.state;

    let endpointUrl = configJSON.getLocationsApiENdPoint;

    let queryParams: string[] = [];
    if (selectedFilter === 'Date Range' && startDate && endDate) {
     const start = startDate.toLocaleDateString('en-GB'); 
     const end = endDate.toLocaleDateString('en-GB'); 
     queryParams.push(`date_range&start_date=${start}&end_date=${end}`);
    }
    
    queryParams = this.query()
   if (queryParams.length === 1) {
    endpointUrl+= `?filter_by=` + `${queryParams[0]}`; 
  } else if (queryParams.length > 1) {
    endpointUrl+= `?filter_by=` + `${queryParams.join("&")}`; 
  }
    const header = {
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLocationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getMember = () => {
   
    const header = {
      token:this.state.token
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMemberCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMemberApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getService = () => {
   
    const header = {
      token:this.state.token
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetServiceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServiceApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getCurrentLocation(){
    
    if (navigator.geolocation) {
     navigator.geolocation.getCurrentPosition(
       (position) => {
         this.setState({
           lat: position.coords.latitude,
           lang: position.coords.longitude,
         });
       }
     );
   } else {
     alert("Geolocation is not supported by this browser.");
   }
 }
 
renderValue = () => {
     if (this.state.selectedService.length === 0) {
       return "Select a Service"
     } else
     return this.state.selectedService[0].service_type_name+"...";
   };
   renderValueTeam = () => {
       if (this.state.selectedTeamMember.length === 0) {
         return "Select a Member"
       } else
       return this.state.selectedTeamMember.map(service => service.first_name+" "+service.last_name).join(', ');
     };
  searchFilterFunction = (text: string) => {
    this.setState({searchtext:text})
   
    const header = {
      token:this.state.token
    }

let endpointUrl = configJSON.searchAPiEndPoint;

let queryParams: string[] = [];
if(text?.length>0){
  
  queryParams.push(`?search_query=${text}`);
}
if (queryParams.length > 0) {
  endpointUrl += queryParams.join('&');
}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpointUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
