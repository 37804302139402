import React from "react";
// Customizable Area Start
import {  
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  Select,
  TextField,Checkbox,
  Dialog,
  IconButton, DialogTitle,
  DialogContent,
  Avatar,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid
   } from "@mui/material";
   import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
   import WallpaperIcon from '@mui/icons-material/Wallpaper';
import {

  styled,
  
} from "@mui/material/styles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  Close as CloseIcon } from "@mui/icons-material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const TextContainer = styled(Box)({
  height:"auto",
  backgroundColor: '#f0f0f0',
  padding:"16px",
});
import { View } from "react-native";
// Customizable Area End

import HealthcareAccessFormController, { Props } from "./HealthcareAccessFormController.web";
import { toInteger } from "lodash";



export default class HealthcareAccessForm extends HealthcareAccessFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  title=()=>{
    return(
      <span>
         {this.state.removeImg?<span style={webStyles.addPictureHeadingText}>Remove Image</span>:
              <span style={webStyles.addPictureHeadingText}>View Image</span>}
      </span>
     
    )
  }
  displayResult = ()=>   {
    return(
    this.state.success &&
    <Box sx={{
      backgroundColor:this.state.uploaded ? '#ECFDF5':"#FEE2E2",  color: '#D97706',
      padding: '10px 10px',
      borderRadius: '4px',                        display: 'flex',
      alignItems: 'center',                        
      borderLeft:this.state.uploaded ?  '5px solid #059669':'5px solid #DC2626',
      marginBottom: 3
    }}>
      <Typography variant="body2" textAlign={"left"} color={this.state.uploaded ? "#2D2A24":"#DC2626"} sx={{ fontWeight: "400",margin:1 }}>
      {this.state.subTitle}
      </Typography>
    </Box>
     )} 
  renderFileListHarm = () => {
    return (
      <List>
        {this.state.selectedFiles?.map((file, index) => (
          <ListItem 
          key={index} 
          divider 
          sx={webStyles.uploadButtonBox1}
          >
            <ListItemAvatar>
              <Avatar 
              style={webStyles.pendingImageBox}>
                <ImageOutlinedIcon 
                style={webStyles.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
            primaryTypographyProps={{
              noWrap: true, 
              style: {
                overflow: "hidden", 
                textOverflow: "ellipsis",  whiteSpace: "nowrap", 
              },
            }}
            primary={file.name} 
            secondary={`${(file.size / 1024).toFixed(2)} KB`}
            />
            <IconButton data-test-id="btnOpenImage" onClick={()=>this.viewImageHarm(file,index)}
            edge="end"> 
            <MoreVertOutlinedIcon />
            </IconButton>
            {this.state.isDropdownOpen==index+1 &&(
                      <Grid 
                      sx={webStyles.dropUI}
                    > <Grid container
                          style={webStyles.dropContainer}
                          data-test-id="btnOpenImageView"
                          onClick={()=>this.setState({openView:true,isDropdownOpen:0})}
                        ><WallpaperIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item><Typography>View</Typography></Grid>
                        </Grid>

                        <Grid container style={{
                            padding: "8px 16px",cursor: "pointer",
                            display: "flex",alignItems: "center",
                            fontFamily:"Inter",gap: "8px",
                          }}
                          data-test-id="btnOpenImageRemovew" onClick={()=>this.setState({removeImg:index.toString(),openView:true})}
                          // onClick={()=>this.handleRemoveFile(index)}
                        >
                         <DeleteOutlineOutlinedIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item>
                            <Typography>
                              Remove</Typography>
                          </Grid></Grid>  </Grid>
            )}
          </ListItem>
        )
      )
        }
      </List>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={"HealthcareAccessForm"} id={"HealthcareAccessForm"}>
      <TextContainer>
          <Button data-test-id="btnGoback" sx={webStyles.goBackButton} onClick={()=>this.goback("LandingPage")}>
            <KeyboardArrowLeftIcon sx={{color:"black"}}/>
            <Typography style={webStyles.goBackText} >Go Back</Typography>
          </Button>
          <Typography style={webStyles.comunityType}>Referrals and Warm Hand Offs</Typography>
         
          <Button fullWidth sx={webStyles.mapButton}>Map</Button>
          <Box sx={{margin:0.5,borderRadius: "8px", }}>
          
          {this.state.allServices?.map((item, index) => (
                        <View data-test-id="View">
                       
                       {item.attributes.name !== "Other Services Offered" ?
                     <Box>
                       <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter",marginTop:2}]}>{item.attributes.name}</Typography>
                       <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select
            multiple
            value={this.state.selectedItems}
            // onChange={this.handleSelectChange}
            key={item.name}
            displayEmpty
            style={webStyles.selectMenu}
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => 'Select'
            }
          >   
         
            {item.attributes.sub_services?.map((option:any) => (
                  <span>
             
              <MenuItem key={option.name} value={option.name}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:  '1px solid #CBD5E1',
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.name} >
                  <Box sx={[webStyles.checkboxContainer,]}>
                    <Checkbox
                    data-test-id="checkBoxIdMenu"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleToggle(item,option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        },
                      }}
                    />
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option.name}</Typography>
                  </Box>
                 
                
                    
                </Box>
              
               
              </MenuItem>
         
              {this.state.openReserveBed && option.name==="Rehabilitation Center" && option.checked ? 
             <span style={{marginRight:2}}>   <MenuItem key={"option.name"} value={"option.name"}
                style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }}
              >
                  <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={"option.name"} >
                    <Box sx={[webStyles.checkboxContainer,]}>
                     
                      <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>Completed Detox Form?</Typography>
                      <Select
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                      placeholder="Yes/No" renderValue={()=>this.state.options ? this.state.options: "Yes/No"} 
                      value={()=>this.state.options}>
                       <MenuItem 
                       data-test-id="checkBoxIdBed1"  style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }} onClick={()=>this.setState({options:"Yes"})}> Yes
                       </MenuItem>
                       <MenuItem data-test-id="checkBoxIdBed2" style={{
                  ...webStyles.checkBoxMenu,
                  borderBottom:  '1px solid #CBD5E1',
                 
                }} onClick={()=>this.setState({options:"No"})}> No
                       </MenuItem>
                      </Select>
                    
                    </Box>
                  </Box>
                </MenuItem>
                 <Button data-test-id="submitBtn" 
                 fullWidth   
                 sx={webStyles.referralButton}
                 >Referral
                 </Button>
                 </span>:
                 null}
                </span>
            ))}
          </Select>
                   </FormControl>

               </Box>  : null      }
                        </View>
                      ))} 
                        </Box>
          <Typography 
          style={webStyles.areaText}>
            Additional Items</Typography>
          <TextField fullWidth
            variant="outlined"
            size="small" multiline
            rows={3} placeholder="Add additional information" style={webStyles.subBoxinputField}
          />
        <Button 
        data-test-id="openModalTestId" 
        onClick={this.handleOpenHarm} 
        fullWidth 
        sx={webStyles.mapButton}>
          Add Picture
        </Button>
        <Button 
        data-test-id="submitBtn"  
        fullWidth sx={webStyles.submitButton}>
          Submit
          </Button>

        <Dialog data-test-id="dialogOpen"
            open={this.state.open} 
            onClose={this.handleCloseHarm}
            fullWidth 
            maxWidth="sm" 
            aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId" 
                aria-label="close"
                onClick={this.handleCloseHarm} 
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileListHarm()}
              <label>
              {this.displayResult()}
                <Box
                  display="flex" 
                  alignItems="center"
                  sx={webStyles.uploadButtonBox1} 
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar 
                  style={webStyles.photoCamera1}>
                    <CameraAltOutlinedIcon style={webStyles.cameraIcon} />
                  </Avatar>
                  <Typography 
                  variant="body1" 
                  style={webStyles.addPictureText}>
                    Add picture
                  </Typography>
                  
                </Box>
               
                <input
                  type="file" 
                  accept="image/*"
                  multiple 
                  hidden 
                  data-test-id="fileChangeImageUpload"
                  onChange={this.handleFileChangeHarm}
                /> 
                </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions> 
                <Button
                  variant="contained"  
                  color="primary" fullWidth
                  data-test-id="uploadTestId"  
                  onClick={this.uploadImagesHarm}  sx={webStyles.uploadButton}
                >Upload
                </Button> </DialogActions>
            )}
          </Dialog>
          <Dialog data-test-id="dialogImageView" 
            open={this.state.openView} onClose={()=>this.handleCloseHarmView}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle>
              {this.title()}
              
              <IconButton data-test-id="closeModalTestId1" aria-label="close"
                onClick={this.handleCloseHarmView} 
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>

            <DialogContent style={{alignContent:"center",alignItems:"center",textAlign:"center",}}
             dividers>
              { this.state.removeImg ? 
              <span><Box sx={{
                backgroundColor: '#FEF3C7', 
                 color: '#D97706',
                padding: '10px 10px',
                borderRadius: '4px',                      
                  display: 'flex',
                alignItems: 'center',                       
                 borderLeft: '4px solid #D97706',
                marginBottom: 3
              }}>
                <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>Are you sure you want to remove the image? </Typography>
              </Box>
                <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                <Grid item xs={6}>  <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none',  color: "black", 
              font: "Inter", fontSize: "16px", fontWeight: "700",  height: "44px"
              ,border:"2px red solid",'&:hover': {  backgroundColor: '#E2E8F0',
              },
            }]}
            onClick={this.handleCloseHarmView}

              data-test-id={"editcancelButton1"}>No 
              </Button></Grid>
                <Grid item xs={6}>
                  <Button sx={[{paddingLeft:"28%",
                   paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none', backgroundColor:"#F5C502", 
              color: "black", font: "Inter", fontSize: "16px",
               fontWeight: "700",height: "44px",
              '&:hover': {
                backgroundColor: '#E2E8F0',
              },
            }]}

            onClick={()=>this.handleRemoveFile(toInteger(this.state.removeImg))}

              data-test-id={"btnEditmodalButton"}>Yes 
              </Button>
              
                </Grid>

              </Grid> 

              </span>

              :
              <img
              
              src={this.state.imageView}
              alt="Uploaded Preview"
              style={{ width: "200px", height: "auto", 
                borderRadius: "8px", alignSelf:"center",textAlign:"center"}}
            />
              }
           
             </DialogContent>
          </Dialog>
        </TextContainer>
        </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  dropUI:{
    position: "absolute",
    top: "100%",
    right: 0,
    background: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    zIndex: 1000,
  },
  dropContainer:{
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    font:"Inter",
    gap: "8px",
  },
  modal:{
    position: 'absolute',
  top: '10%',
  margin:2,
  backgroundColor:"#F6F6F3",
  
  padding:2,
  
  },
  subBoxinputField: {
    marginBottom:"12px",
    fontSize: "16px",
    width:"100%",
    // color: "#94A3B8",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    backgroundColor: "white",
    fontWeight: 400,
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
    }
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"2px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  referralButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
    // marginLeft:2,
    marginRight:2
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  photoCamera1: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  uploadButtonBox1: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
// Customizable Area End
