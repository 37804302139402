import React from "react";
// Customizable Area Start
import {  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  TableContainer,
  Paper,Input,TextField,Modal,
  Dialog,
  DialogContent,
  Avatar,
  IconButton,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Grid
   } from "@mui/material";
  
import {
  createTheme,
  styled,
  
} from "@mui/material/styles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Location from "../../location/src/Location.web";
// Customizable Area End

import HarmReductionFormController, { Props } from "./HarmReductionFormController.web";
import { toInteger } from "lodash";




export default class HarmReductionForm extends HarmReductionFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModalHarm = () => (
    <Dialog
      open={this.state.successModal}
      onClose={this.handleCloseModalharm}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={webStyles.modalHeading}>
        {this.state.title}
        </Typography>
        <IconButton
        data-test-id="btnhandleCloseModalharm"
          onClick={this.handleCloseModalharm}
          sx={{ position: 'absolute', right: 8, top: 2 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
      {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "green",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
        <Typography variant="h6" sx={webStyles.arrowIcon}>
        {this.state.subTitle}
        </Typography>
      </DialogContent>
    </Dialog>
  )
  displayResult = ()=>   {
    return(
    this.state.success &&
    <Box sx={{
      backgroundColor:this.state.uploaded ? '#ECFDF5':"#FEE2E2",  color: '#D97706',
      padding: '10px 10px',
      borderRadius: '4px',                        display: 'flex',
      alignItems: 'center',                        
      borderLeft:this.state.uploaded ?  '5px solid #059669':'5px solid #DC2626',
      marginBottom: 3
    }}>
      <Typography variant="body2" textAlign={"left"} color={this.state.uploaded ? "#2D2A24":"#DC2626"} sx={{ fontWeight: "400",margin:1 }}>
      {this.state.subTitle}
      </Typography>
    </Box>
     )} 
  renderFileListHarm = () => {
    return (
      <List>
        {this.state.selectedFiles.map((file, index) => (
          <ListItem key={index} divider sx={webStyles.uploadButtonBox1}>

            <ListItemAvatar>
              <Avatar style={webStyles.pendingImageBox}><ImageOutlinedIcon style={webStyles.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`${(file.size / 1024).toFixed(2)} KB`}
            primaryTypographyProps={{
              noWrap: true, style: {
                overflow: "hidden", 
                textOverflow: "ellipsis", whiteSpace: "nowrap", 
              },
            }}
            />
            <IconButton data-test-id="btnOpenImage" onClick={()=>this.viewImageHarm(file,index)} edge="end"> <MoreVertOutlinedIcon />
            </IconButton>
            {this.state.isDropdownOpen==index+1 &&(
                      <Grid
                      sx={webStyles.dropUI}
                    > <Grid container
                          style={webStyles.dropContainer}
                          data-test-id="btnOpenImageView"
                          onClick={()=>this.setState({openView:true,isDropdownOpen:0})}
                        ><WallpaperIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item><Typography>View</Typography></Grid>
                        </Grid>

                        <Grid container style={{
                            padding: "8px 16px",cursor: "pointer",
                            display: "flex",alignItems: "center",
                            fontFamily:"Inter",gap: "8px",
                          }}
                          data-test-id="btnOpenImageRemovew" onClick={()=>this.setState({removeImg:index.toString(),openView:true})}
                          // onClick={()=>this.handleRemoveFile(index)}
                        >
                         <DeleteOutlineOutlinedIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item>
                            <Typography>
                              Remove</Typography>
                          </Grid></Grid>  </Grid>
            )}</ListItem> ))} </List>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={""} >
      <TextContainer>
      <Button data-test-id="btnGoback" onClick={()=>this.goback("LandingPage")} sx={webStyles.goBackButton}>
        <KeyboardArrowLeftIcon sx={{color:"black"}} />
        <Typography style={webStyles.goBackText}>Go Back</Typography>
      </Button>
      <Typography style={webStyles.comunityType}>Harm Reduction Supplies Distribution</Typography>
      
      <Button data-test-id="btnGoMap" onClick={()=>this.setState({openMap:true})} fullWidth sx={webStyles.mapButton}>Map</Button>
      <Typography variant="h6" style={webStyles.areaText}>Event Name</Typography>
        <FormControl fullWidth variant="outlined"
        sx={{backgroundColor: '#ffffff',borderRadius: '8px',border: "1px solid #CBD5E1",   "& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {
          borderColor: "transparent", }
        },}} >
        <TextField
      placeholder="Add Event Name"
      multiline
      rows={4} 
      data-test-id="eventName"
      variant="outlined"
      fullWidth
      value={this.state.showEdit ? this.state.getHarmFormDetails?.data?.attributes?.event_name : this.state.eventName}
      onChange={this.setEvent}
    />
        </FormControl>
      <Box>
     
        <Typography variant="h6" style={webStyles.areaText}>Items Supplied to Community</Typography>
        <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
          <Select
            multiple
            value={this.state.selectedItems}
            onChange={this.handleSelectChange}
            displayEmpty
            onClose={(this.updateEstimatedTotal)}
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => 'Select items'
            }
            style={webStyles.selectMenu}
          >
            {this.state.items?.map((item, index) => (
              <span >
              {item.input_type !=="text_field" ?
              <MenuItem key={item.name} value={item.name}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:  '1px solid #CBD5E1',
               
              }}
            >
                <Box data-test-id="checkBoxId" sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={item.name} onClick={() => this.handleToggle(index)}>
                  <Box sx={[webStyles.checkboxContainer,]}>
                    <Checkbox
                      edge="start"
                      checked={item.checked}
                      tabIndex={-1}
                      disableRipple
                     
                      
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "16px",
                        
                        '&.Mui-checked': {
                          color: "#CF9500",
                        },
                      }}
                    />
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{item.name}</Typography>
                  </Box>
                 
                
                    
                </Box>
              
                <Input 
                  key={item.id}
                        type={"number"}
                        disabled={!item.checked}
                        disableUnderline
                        sx={{width:50,  "& input[type=number]": {
                          MozAppearance: "textfield", // Firefox
                          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        },}} style={webStyles.listItemQuantity}
                      inputProps={{
                        style: { cursor: "number" }  
                      }}
                    placeholder="#"
                        data-test-id="txtInputText"
                        value={ item.count==0?"":item.count}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.checkInt(item,e)}
                      />
              </MenuItem>:
              <span>
              <MenuItem key={item.name} value={item.name}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom: '1px solid #CBD5E1',
              }}
            >
                <Box data-test-id="checkBoxId1"sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={item.name} onClick={() => this.handleToggle(index)}>
                  <Box sx={[webStyles.checkboxContainer,]}>
                    <Checkbox
                      edge="start"
                      checked={item.checked}
                      tabIndex={-1}
                      disableRipple 
                      sx={{
                        color: "#64748B",width:"10%",borderRadius: "6px",'&.Mui-checked': {
                          color: "#CF9500",
                        },
                      }}
                    />
                    <Typography sx={{ textAlign: "left",}} style={webStyles.listItemName}>{item.name}</Typography>
                  </Box>
                 
                
                    
                </Box>
              
                
              </MenuItem>
              <Input 
                  key={item.id}
                        type={"text"}
                        disabled={!item.checked}
                        disableUnderline
                        sx={[webStyles.listItemName,{ width: "100%",padding:1,paddingLeft:2.5,borderBottom: index === this.state.items.length - 1 ? 'none' : '1px solid #CBD5E1',alignItems:"flex-end", '& input[type=number]': {
                          MozAppearance: 'textfield', 
                        },'& input[type=number]::-webkit-outer-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,},'& input[type=number]::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0,
                        },
                      }]}
                      placeholder="Enter Narcan Training"
                      inputProps={{ style: { cursor: "text" } }}
                        data-test-id="txtInputText"
                        value={this.state.NarcanTraining}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => this.checkInt(item,e)}
                      />
              </span>}
              </span>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" style={webStyles.areaText}>
          {this.state.labelTextSecond}
        </Typography>
        <TableContainer component={Paper} style={webStyles.tableContainerBox}>
          <Table>
            <TableBody>
              {this.state.items?.filter((item) => item.checked && item.input_type !=="text_field")
                .map((item) => (
                  <StyledTableRow key={item.name}>
                    
                    <StyledTableCell style={{ background: "#F1F5F9" }}>{item.name}</StyledTableCell>
                    
                    <StyledTableCell align="right" style={{ fontSize: "16px", fontWeight: 400 }}>
                    <Input value={item.count}
                      onChange={(e: { target: { value: any; }; }) => this.handleCountChange(e, item.name)}
                      size="small" type="number"
                      data-test-id="txtInputcountChnage" inputProps={{ min: 0 }} 
                      disableUnderline sx={{width:50,  "& input[type=number]": {
                        MozAppearance: "textfield", "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                          WebkitAppearance: "none", margin: 0,
                        },
                      },}} style={webStyles.listItemQuantity}
                    />
                    </StyledTableCell> </StyledTableRow>
                ))}
                  {this.state.NarcanTraining.length>0 ?
                  <Box >
                    <StyledTableRow sx={{ borderBottom: '1px solid #CBD5E1' }}  key={this.state.NarcanTraining}>
                      <StyledTableCell sx={{ borderBottom: '1px solid #CBD5E1', }}>*Narcan Training :</StyledTableCell>
                    </StyledTableRow> 
                    
                    <StyledTableRow >
                    <StyledTableCell sx={{ borderBottom: '1px solid #CBD5E1', }} style={{ width: "100%",fontSize: "16px", fontWeight: 400 }}>{this.state.NarcanTraining}</StyledTableCell>
                    </StyledTableRow>
                   </Box>
                    : null}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={webStyles.estimatedBox1}>
          <Typography variant="h6" style={webStyles.estimatedItems}>
          Total Number of Items:
          </Typography>
          <Typography style={webStyles.totalItems1}> {this.state.estimatedTotal}</Typography>
        </Box>

        <Button data-test-id="openModalTestId" onClick={this.handleOpenHarm} fullWidth sx={webStyles.mapButton}>Add Picture</Button>
       <Button   data-test-id="submitBtn" onClick={this.state.showEdit ? this.editHarmReductionForm : this.submitHarmReduction} fullWidth sx={webStyles.submitButton1}>Submit</Button>
        
      </Box>
      {this.renderModalHarm()}
      
      <Dialog data-test-id="dialogImageUpload"
            open={this.state.open} onClose={()=>this.handleCloseHarm}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId" aria-label="close"
                onClick={this.handleCloseHarm} sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileListHarm()}
              <label>
                {this.displayResult()}
           
                <Box
                  display="flex" alignItems="center"
                  sx={webStyles.uploadButtonBox1} style={{ cursor: 'pointer' }}
                >
                  <Avatar style={webStyles.photoCamera1}>
                    <CameraAltOutlinedIcon style={webStyles.cameraIcon} />
                  </Avatar>
                  <Typography variant="body1" style={webStyles.addPictureText}>
                    Add picture
                  </Typography>
                </Box>
               
                <input data-test-id="fileUploadImageAdd"
                  type="file" accept="image/*"
                  multiple hidden onChange={this.handleFileChangeHarm}
                /> </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions> <Button
                  variant="contained"  color="primary" fullWidth
                   
                  data-test-id="uploadTestId"  onClick={this.uploadImagesHarm}  sx={webStyles.uploadButton}
                >Upload

                </Button>

              </DialogActions>

             )} 

          </Dialog>
          <Dialog data-test-id="dialogImageView" 
            open={this.state.openView} onClose={()=>this.handleCloseHarmView}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle>
              {this.state.removeImg?<span style={webStyles.addPictureHeadingText}>Remove Image</span>:
              <span style={webStyles.addPictureHeadingText}>View Image</span>}
              <IconButton data-test-id="closeModalTestId1" aria-label="close"
                onClick={this.handleCloseHarmView} 
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>

            <DialogContent style={{alignContent:"center",alignItems:"center",textAlign:"center",}}
             dividers>
              { this.state.removeImg ? 
              <span><Box sx={{
                backgroundColor: '#FEF3C7', 
                 color: '#D97706',
                padding: '10px 10px',
                borderRadius: '4px',                      
                  display: 'flex',
                alignItems: 'center',                       
                 borderLeft: '4px solid #D97706',
                marginBottom: 3
              }}>
                <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>Are you sure you want to remove the image? </Typography>
              </Box>
                <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                <Grid item xs={6}>  <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none',  color: "black", 
              font: "Inter", fontSize: "16px", fontWeight: "700",  height: "44px"
              ,border:"2px red solid",'&:hover': {  backgroundColor: '#E2E8F0',
              },
            }]}
            onClick={this.handleCloseHarmView}

              data-test-id={"editcancelButton1"}>No 
              </Button></Grid>
                <Grid item xs={6}>
                  <Button sx={[{paddingLeft:"28%",
                   paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none', backgroundColor:"#F5C502", 
              color: "black", font: "Inter", fontSize: "16px",
               fontWeight: "700",height: "44px",
              '&:hover': {
                backgroundColor: '#E2E8F0',
              },
            }]}

            onClick={()=>this.handleRemoveFile(toInteger(this.state.removeImg))}

              data-test-id={"btnEditmodalButton"}>Yes 
              </Button>
              
                </Grid>

              </Grid> 

              </span>

              :
              <img
              
              src={this.state.imageView}
              alt="Uploaded Preview"
              style={{ width: "200px", height: "auto", 
                borderRadius: "8px", alignSelf:"center",textAlign:"center"}}
            />
              }
           
             </DialogContent>
          </Dialog>
          <Dialog data-test-id="dialogMapView" 
            open={this.state.openMap} onClose={()=>this.setState({openMap:false})}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Map</span>
              <IconButton data-test-id="closeModalmap" aria-label="close"
                onClick={()=>this.setState({openMap:false})} 
                sx={webStyles.addPictureIcon}
              >
                <CloseIcon /> </IconButton>
            </DialogTitle>

            <DialogContent  >
            <Location sendDataToPage1={this.handleDataFromPage2} data={"harm_reduction_form"} navigation={this.props.navigation} id={""} />
             </DialogContent>
          </Dialog>
    </TextContainer>
    </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TextContainer = styled(Box)({
  height:"auto",
  backgroundColor: '#f0f0f0',
  padding:"16px",
});
const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
  fontWeight: 700,
  borderBottom: '1px solid #CBD5E1', 
  padding: '16px', 
  color:"#2D2A24"
});

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 'none',
  },
});
const webStyles = {
  dropUI:{
    position: "absolute",
    top: "100%",
    right: 0,
    background: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    zIndex: 1000,
  },
  dropContainer:{
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    font:"Inter",
    gap: "8px",
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"8px",
  },
  
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },

  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  itemsMainBox2: {
    display:"flex",
    flexDirection: "column",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  estimatedBox1: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  formControlTextField: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
  
  },
  
  totalItems1: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  photoCamera1: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  uploadButtonBox1: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
 
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },

  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },  
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  }, umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },  modalHeading: {
    width:"80%",
    fontWeight: 700,
    color:"#2D2A24",
    fontSize:"24px", 
  },
  arrowIcon: {
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton1: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

// Customizable Area End
