Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";
exports.labelTitle = "Submit";
exports.referrelsTitle = "Submit";


exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;
exports.getHarmReductionSupplies="bx_block_custom_forms/harm_reduction_supplies/fetch_supplied_items?supply_item_type=harm_reduction_form"
exports.addHarmReductionEndPoint="bx_block_custom_forms/harm_reduction_supplies"
exports.careActionEndPoint= "bx_block_custom_forms/performed_and_facilitated_cares/fetch_care_actions";
exports.submitActionRequestEndPoint= "bx_block_custom_forms/performed_and_facilitated_cares";
exports.addPictureEndPoint = "bx_block_attachment/file_attachments/direct_upload";
exports.uploadImageApi ="bx_block_attachment/file_attachments/direct_upload"
exports.getSuppliedItemsapiEndpoint="bx_block_custom_forms/harm_reduction_supplies/fetch_supplied_items?supply_item_type=community_safety_form"
exports.subitCleanlinessForm="bx_block_custom_forms/community_safety_and_cleanliness"
exports.orgnisationListApiEndPoint="bx_block_order_reservations/referrals/fetch_referral_organisation"

exports.getFormsListApiEndPoint = "bx_block_custom_forms/update_submitted_forms";
exports.getFormsListApiMethod = "GET";

exports.subitCleanlinessForm="bx_block_custom_forms/community_safety_and_cleanliness"
exports.methodapi = "GET";
exports.getAllServices="bx_block_content_management/service_offereds"
exports.getOrgApiENdPoint="bx_block_content_management/organizations"
exports.getReferralsApiEndpoint="bx_block_order_reservations/referrals.json"
exports.showReferralApiEndpoint="bx_block_order_reservations/referrals/"
exports.cancelReferralApiEndpoint="bx_block_order_reservations/referrals/cancelled?id="
exports.getBedData="bx_block_order_reservations/referrals/organization_info_with_beds_availability?sub_service_id="
exports.updateReferralApiEndpoint="bx_block_order_reservations/referrals/"
exports.getReferralList="bx_block_order_reservations/referral_organizations/bed_available_org?sub_service_id="
exports.newGerrefferalsAPI="bx_block_order_reservations/referrals/filter"
// Customizable Area End