import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// interface File {
//   name: string;
//   size: number;
//   status: string;
//   progress: number;
// }

interface TestingLocation {
  id: number;
  name: string;
  checked?: boolean;
}

interface CareAction {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    input_type: string;
    testing_locations: TestingLocation[];
  };
  checked?: boolean;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  isModalOpen:boolean;
  isImageModalOpen:boolean;
  isRemoveImageModalOpen: boolean,
  isViewImageModalOpen: boolean,
  errorMessage: string;
  token: string;
  careActions: CareAction[]
  selectedCareActionId: number,
  selectedTestingLocationId: number | null,
  comment: string,
  image_ids: number[],
  locationOfPurchase: string,
  bagName: string,
  substance: string,
  drugTestResults: string,
  lat: number,
  lng: number,
  responseMessage:string,
  open:boolean;
  selectedFiles: File[];
  isUploading: boolean;
  formId: string,
  formType: string,
  isDropdownOpen:number;
  openView:boolean,
  imageView:string,
  uploadedImg:boolean,
  removeImg:string,
  successImg:boolean,
  subTitleImg:string,
  openMap:boolean,
  selectedPindata:  {
    icon:string,
    id:number,
    name:string
  },
  

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class AdministratedServicesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchCareActionCallId: string = "";
  submitDataCallId: string = "";
  uploadImageCallId: string = "";
  apiCallIdGetAdministratedFormDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      isImageModalOpen: false,
      isRemoveImageModalOpen: false,
      isViewImageModalOpen: false,
      errorMessage: "",
      token: "",
      careActions: [],
      selectedCareActionId: 0,
      selectedTestingLocationId: null,
      comment: "",
      image_ids: [],
      locationOfPurchase: "",
      bagName: "",
      substance: "",
      drugTestResults: "",
      lat: 0,
      lng: 0,
      responseMessage:"",
      open: false,
      selectedFiles: [],
      isUploading: false,
      formId: "",
      formType: "",
      isDropdownOpen:0,
      openView:false,
      imageView:"",
      uploadedImg:false,
      removeImg:"",
      successImg:false,
      subTitleImg:"",
      openMap:false,
      selectedPindata:  {
        icon:"",
        id:0,
        name:""
      }
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.fetchCareActionCallId:
          if (responseJson) {
            const careActions = responseJson.data.map((action: any) => ({
              id: action.id,
              type: action.type,
              attributes: {
                id: action.attributes.id,
                name: action.attributes.name,
                input_type: action.attributes.input_type,
                testing_locations: action.attributes.testing_locations && action.attributes.testing_locations.length > 0
                  ? action.attributes.testing_locations.map((location: any) => ({
                    ...location,
                    checked: false,
                  }))
                  : [],
              },
              checked: false,
            }));
            this.setState({ careActions });
          } else {
            this.setState({ errorMessage: "Failed to fetch data." });
          }
          break;
      
        case this.submitDataCallId:
          this.handlesubmitData(responseJson);
          break;
      
        case this.uploadImageCallId:
          this.handleImageUpload(responseJson);
          break;
      
        case this.apiCallIdGetAdministratedFormDetails:
          console.log("popppppppppp555555", responseJson);
          break;
      
        default:
          console.warn("Unhandled API request call ID:", apiRequestCallId);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getFormDetails = async () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
       token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetAdministratedFormDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.apiCallIdGetAdministratedFormDetails;
};

  handleImageUpload = (responseJson:any) => {
    if (responseJson && responseJson.message === "Images uploaded successfully") {  
      const uploadedBlob = responseJson.blobs;
      const uploadedImageId = uploadedBlob.map((blob: { id: any; }) => blob.id);
      this.setState((prevState) => ({
        image_ids: [...prevState.image_ids, uploadedImageId], 
      }));
      this.setState((prevState) => ({
        selectedFiles: prevState.selectedFiles.map((file) =>
          file.name === uploadedBlob.filename ? { ...file, status: "uploaded" } : file
        ),
        responseMessage: responseJson.message,
      }));
      this.setState({selectedFiles:[],successImg:true,subTitleImg:"Image upload is successful",uploadedImg:true})
      setTimeout(() => {
        this.setState({ successImg: false }); 
      }, 2000);
    } else {
      this.setState({ isUploading: false,successImg:true,subTitleImg:responseJson.errors[0].message,uploadedImg:false });
    }

  }

  handlesubmitData = (responseJson?:{message:string}) =>{
    if (responseJson && responseJson.message) {
      this.setState({ responseMessage: "Data submitted successfully.", isModalOpen: true });
    }
  }
  async componentDidMount() {
    this.fetchCareActions();
    const formid = await getStorageData("formId");
    const formtype = await getStorageData("formType");
    this.setState({
      formId: formid,
      formType: formtype
    });
    this.getFormDetails()
  }

  handleToggle = (index: number) => {
    this.setState((prevState) => {
      const updatedCareActions = prevState.careActions.map((action, i) => ({
        ...action,
        checked: i === index ? !action.checked : false, 
      }));
  
      const selectedCareActionId = updatedCareActions[index].checked
        ? updatedCareActions[index].id 
        : 0; 
  
      return {
        careActions: updatedCareActions,
        selectedCareActionId, 
      };
    });
  };
  
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
  
    this.setState({ comment: value });
  };  

  handleNestedCheckboxToggle = (careActionIndex: number, locationIndex: number) => {
    this.setState((prevState) => {
      const updatedCareActions = prevState.careActions.map((action, i) => {
        if (i === careActionIndex) {
          const updatedLocations = action.attributes.testing_locations.map((location, j) => {
            const isChecked = j === locationIndex ? !location.checked : false;
            return { ...location, checked: isChecked };
          });
  
          return {
            ...action,
            attributes: {
              ...action.attributes,
              testing_locations: updatedLocations,
            },
          };
        }
        return action;
      });
  
      const selectedTestingLocationId =
        updatedCareActions[careActionIndex].attributes.testing_locations[locationIndex].checked
          ? updatedCareActions[careActionIndex].attributes.testing_locations[locationIndex].id
          : 0; 
      return {
        careActions: updatedCareActions,
        selectedTestingLocationId,
      };
    });
  };
  
  

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOpenImageModal = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleCloseImageModal = () => {
    this.setState({ isImageModalOpen: false });
  };

  handleGoBack = () => {
    window.history.back();
  };

  handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token})
  };

  fetchCareActions = async() => {
    const headers = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: await getStorageData("token"),
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.fetchCareActionCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.careActionEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchFormDataMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmit = async() => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: await getStorageData("token"),
    };

    const requestData = {
      performed_and_facilitated_care: {
        worker_id: 1,
        care_action_id: this.state.selectedCareActionId,
        testing_location_id: this.state.selectedTestingLocationId,
        comment: this.state.comment,
        image_ids: this.state.image_ids.flat(),
        map_form_icons_id:this.state.selectedPindata.id,
        location_of_point_of_purchase: this.state.locationOfPurchase,
        bag_name: this.state.bagName,
        substance: this.state.substance,
        drug_test_results: this.state.drugTestResults,
        lat:this.state.lat,
        lng:this.state.lng
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.submitDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitActionRequestEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      comment: "",
      locationOfPurchase: "",
      bagName: "",
      substance: "",
      drugTestResults: "",
    });

  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, selectedFiles: [] });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
   
    if (files) {
      this.setState((prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...Array.from(files)],
      }));
    }
  };
  
  uploadImages = async() => {
    const { selectedFiles } = this.state;
  
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("blob[]", new File([file as any], file.name, { type: "image/png" })); 
    });
  
    const headers = {
      token: await getStorageData("token"),
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadImageCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPictureEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isUploading: true });
  };
  handleRemoveFile = (index: number) => {
    this.setState({isDropdownOpen:0, openView:false,removeImg:"",imageView:""})
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.filter((_, i) => i !== index),
    }));
  };
  handleCloseHarmView = () => {
    this.setState({openView:false });
  };
  viewImage(file1: File,index:number){
    this.setState({isDropdownOpen:index+1})
    if (file1) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({imageView:reader.result as string})
      };
      reader.readAsDataURL(file1); 
    }
    
  }
  handleDataFrompin = (data: {icon:string,id:number,name:string},lattitude:number,lang:number) => {
   
    this.setState({ selectedPindata: data,lat:lattitude,lng:lang }); 
  };
  // Customizable Area End
}
